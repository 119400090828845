import { useCallback, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import useKeyPress from '@hooks/useKeyPress'

export const KeyLogger = () => {
  const copy = useKeyPress('t')
  const { getIdTokenClaims } = useAuth0()

  const copyTokenToClipboard = useCallback(async () => {
    try {
      const token = await getIdTokenClaims()
      navigator.clipboard.writeText(`Bearer ${token?.__raw}`)
    } catch (e) {}
  }, [getIdTokenClaims])

  useEffect(() => {
    if (copy) {
      copyTokenToClipboard()
    }
  }, [copy, copyTokenToClipboard])

  return null
}
