import * as yup from 'yup'

import {
  MarketplaceCollectionUpdateInput,
  UpdateCollectionMutationVariables,
} from '@services/graphql/generated'
import { Shape } from '@types'

export const updateCollectionMutationValidation = yup.object<
  Shape<UpdateCollectionMutationVariables>
>({
  data: yup.object<Shape<MarketplaceCollectionUpdateInput>>({
    description: yup.string().notRequired(),
    endDate: yup.string().notRequired(),
    name: yup.string().notRequired(),
    slug: yup.string().notRequired(),
    startDate: yup.date().notRequired(),
    status: yup.string().notRequired(),
    artistIDs: yup.array().notRequired(),
    redirectUrl: yup.string().notRequired(),
  }),
  id: yup.string().required('Collection ID is required'),
})
