import { ApolloError } from '@apollo/client'

import { ErrorMessage, Loading } from '@shared'

interface BoundaryProps {
  isLoading: true
  customLoader?: React.ReactNode
  error?: ApolloError
  customError?: React.FC<{
    error: ApolloError
  }>
  defaultLoaderProps?: React.ComponentProps<typeof Loading>
}

interface BoundaryWithoutLoaderProps {
  isLoading?: false
  error?: ApolloError
  customError?: React.FC<{
    error: ApolloError
  }>
}

/**
 *
 * @param isLoading - if true, will render the customLoader if provided, otherwise will render the default loading component
 * @param error - if provided, will render the customError if provided, otherwise will render the default error message
 * @param customError - if provided, will render the error message provided
 * @param customLoader - if provided, will render the loader provided
 * @param children - the children to render
 * @returns returns the children or the customError or the customLoader or the default loading component
 */
export const Boundary: React.FC<BoundaryWithoutLoaderProps | BoundaryProps> = ({
  customError: CustomError,
  children,
  error,
  ...props
}) => (
  <>
    {error ? (
      CustomError ? (
        <CustomError error={error} />
      ) : (
        <ErrorMessage error={error} />
      )
    ) : props.isLoading ? (
      props.customLoader || <Loading {...props.defaultLoaderProps} />
    ) : (
      <>{children}</>
    )}
  </>
)
