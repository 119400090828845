import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const HomeIcon = memo(
  createSvgIcon(
    <path d="M19.6434 7.56649L10.6042 0.219133C10.4347 0.077556 10.2209 0 10 0C9.77914 0 9.56529 0.077556 9.39578 0.219133L0.35659 7.56649C0.158847 7.7274 0.0326638 7.95989 0.0055048 8.21338C-0.0216542 8.46686 0.05241 8.72081 0.211576 8.91996C0.376757 9.11929 0.613386 9.24607 0.870843 9.27317C1.1283 9.30027 1.38614 9.22554 1.58921 9.06497L2.65264 8.19489V14.5997C2.65264 14.9171 2.71515 15.2313 2.83662 15.5246C2.95808 15.8178 3.1361 16.0842 3.36053 16.3087C3.58496 16.5331 3.8514 16.7111 4.14463 16.8326C4.43786 16.954 4.75214 17.0166 5.06953 17.0166H14.9305C15.2479 17.0166 15.5621 16.954 15.8554 16.8326C16.1486 16.7111 16.415 16.5331 16.6395 16.3087C16.8639 16.0842 17.0419 15.8178 17.1634 15.5246C17.2848 15.2313 17.3474 14.9171 17.3474 14.5997V8.19489L18.4108 9.06497C18.5908 9.20434 18.8116 9.28076 19.0392 9.28249C19.1832 9.28168 19.3252 9.24871 19.4548 9.18598C19.5844 9.12326 19.6984 9.03237 19.7884 8.91996C19.9476 8.72081 20.0217 8.46686 19.9945 8.21338C19.9673 7.95989 19.8412 7.7274 19.6434 7.56649V7.56649ZM15.4138 14.5997C15.4138 14.7279 15.3629 14.8508 15.2723 14.9415C15.1816 15.0321 15.0587 15.083 14.9305 15.083H5.06953C4.94133 15.083 4.81839 15.0321 4.72773 14.9415C4.63708 14.8508 4.58616 14.7279 4.58616 14.5997V6.62391L10 2.22516L15.4138 6.62391V14.5997Z" />,
    'HomeIcon'
  )
)
