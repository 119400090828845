import Box from '@mui/material/Box'

interface DottedContainerProps {
  children: React.ReactNode
  innerContent?: React.ReactNode
  disablePadding?: boolean
}

export const DottedContainer = ({
  children,
  innerContent,
  disablePadding,
}: DottedContainerProps) => (
  <Box
    className="DottedContainer"
    sx={theme => ({
      ...theme.content.dottedBackground,
      flexGrow: 1,
      overflowY: 'auto',
      height: '100%',
    })}
  >
    {innerContent && <Box p={2.5}>{innerContent}</Box>}
    <Box p={disablePadding ? 0 : 2.5} flexGrow="1" borderRadius="10px">
      {children}
    </Box>
  </Box>
)
