import * as yup from 'yup'

import {
  TokenDraft,
  UpdateTokenDraftMutationVariables,
} from '@services/graphql/generated'
import { Shape } from '@types'
import { isValidJSONString } from '../utils/helpers'

export const updateTokenDraftMutationValidation = (isErc1155: boolean) =>
  yup.object<Shape<UpdateTokenDraftMutationVariables>>({
    token: yup
      .object<Shape<TokenDraft>>({
        assetId: yup.string().notRequired(),
        copyright: yup.string().notRequired(),
        description: yup
          .string()
          .required('description is required.')
          .min(3, 'Min length is 3.'),
        editions: yup.number().nullable().notRequired(),
        invoiceItemId: yup.string().notRequired(),
        metadataJSON: yup
          .string()
          .test('is-valid-json', 'Invalid JSON format', value =>
            isValidJSONString(value)
          ),
        name: yup
          .string()
          .required(`name is required.`)
          .min(3, 'Min length is 3.'),
        royaltyBasisPoints: yup.number().notRequired(),
        tokenId: yup.string().notRequired(),
        ...(isErc1155 && {
          editions: yup.number().required(`editions is required.`).positive(),
        }),
      })
      .required(),
  })
