export const getTimeFromSeconds = seconds => {
  if (seconds == 0) return 'No suspension time'
  let hours = Math.floor(seconds / 3600)
  if (hours < 1) return '< 1 hour'
  const days = Math.floor(hours / 24)
  if (days < 1) return `~ ${hours} ${hours === 1 ? 'hour' : 'hours'}`
  hours = hours % 24
  if (hours === 0) return `~ ${days} ${days === 1 ? 'day' : 'days'}`
  return `~ ${days} ${days === 1 ? 'day' : 'days'} ${hours} ${
    hours === 1 ? 'hour' : 'hours'
  }`
}
