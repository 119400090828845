import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { EColor } from '@types'
import { ReturnHeader } from './ReturnHeader'

interface PageToolbarProps {
  title?: string
  withReturn?: boolean
  returnLabel?: string
  returnPath?: string
  returnAction?: () => void
  returnComponent?: React.ReactNode
  actions?: React.ReactNode
  secondary?: boolean
}

export const PageToolbar = ({
  title,
  actions,
  withReturn,
  returnLabel,
  returnPath,
  returnComponent,
  returnAction,
  secondary,
}: PageToolbarProps) => (
  <StyledWrapper
    justifyContent={'space-between'}
    display="grid"
    gridTemplateAreas={'"return title action"'}
    gridTemplateColumns="auto auto auto"
    sx={{ background: secondary ? EColor.Neutral100 : EColor.White }}
    alignItems="center"
    p={1}
  >
    {withReturn ? (
      <ReturnHeader
        returnText={returnLabel}
        returnAction={returnAction}
        returnComponent={returnComponent}
        path={returnPath}
      />
    ) : (
      <Box></Box>
    )}
    {title ? (
      <StyledTitle variant="h4" textAlign="center">
        {title}
      </StyledTitle>
    ) : (
      <Box></Box>
    )}
    {actions ? (
      <StyledActions>{actions}</StyledActions>
    ) : (
      <Box minWidth={120}></Box>
    )}
  </StyledWrapper>
)

const StyledWrapper = styled(Box)({
  borderBottom: `1px solid ${EColor.Gray200}`,
  padding: 0,
  minHeight: 50,
})

const StyledTitle = styled(Typography)(({ theme }) => ({
  gridArea: 'title',
  fontFamily: theme.fonts.secondary,
  fontWeight: 600,
  fontSize: 13.2154,
  lineHeight: '17px',
  textTransform: 'uppercase',
}))

const StyledActions = styled(Box)(({ theme }) => ({
  borderLeft: theme.content.border,
  height: '100%',
  gridArea: 'action',
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'center',
}))
