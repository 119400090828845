import React, { memo } from 'react'

import Box from '@mui/material/Box'

import { DottedContainer } from '../DottedContainer'
import { ListControls, ListGrid, ListProvider } from './index'

interface ListProps {
  subHeader?: string
  actions?: React.ReactNode
  children: React.ReactNode
  hideControls?: boolean
  withPagination?: boolean
  column?: boolean
}

export const List = ({
  column,
  subHeader,
  actions,
  children,
  hideControls = false,
  withPagination = false,
}: ListProps) => (
  <Box width="100%" display="flex" flexDirection="column" height="100%">
    <ListProvider>
      <Box>
        <ListControls
          column={column}
          hideControls={hideControls}
          actions={actions}
        />
      </Box>
      <DottedContainer>
        <ListGrid subHeader={subHeader} withPagination={withPagination}>
          {children}
        </ListGrid>
      </DottedContainer>
    </ListProvider>
  </Box>
)

export default memo(List)
