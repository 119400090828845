import { ContractType } from '@services/graphql/generated'

export const generateContractNameLabel = (contractType: ContractType) => {
  switch (contractType) {
    case ContractType.Erc1155Creator:
      return 'ERC-1155'
    case ContractType.Erc721Creator:
      return 'ERC-721'
    default:
      return 'ERC-721'
  }
}
