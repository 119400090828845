import { useState } from 'react'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { styled } from '@mui/material/styles'

interface ThreeDotsMenuProps {
  vertical?: boolean
  children: React.ReactNode
}

export const ThreeDotsMenu = ({
  vertical = false,
  children,
}: ThreeDotsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const moreMenuOpen = Boolean(anchorEl)

  const handleClickMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMoreMenu = () => {
    setAnchorEl(null)
  }

  return (
    <StyledWrapper
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={moreMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onTouchStart={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        onClick={handleClickMoreMenu}
        sx={{ padding: 0 }}
      >
        {vertical ? (
          <MoreVertIcon className="MoreMenuButton" />
        ) : (
          <MoreHorizIcon className="MoreMenuButton" />
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={moreMenuOpen}
        onClose={handleCloseMoreMenu}
        onClick={handleCloseMoreMenu}
      >
        {children}
      </Menu>
    </StyledWrapper>
  )
}

const StyledWrapper = styled('div')(({ theme }) => ({
  height: 'fit-content',
  '& .MoreMenuButton': {
    color: theme.colors.primary.dark,
  },
  '&:hover': {
    '& .MoreMenuButton': {
      color: theme.colors.primary.green,
    },
  },
}))
