import { ChangeEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

import useDebounce from '@hooks/useDebounce'
import { SearchIcon } from './Icons'
import { useList } from './List'

interface SearchInputProps {
  searchFor: string
  placeholder?: string
}

export const SearchInput = ({ searchFor, placeholder }: SearchInputProps) => {
  const { query } = useRouter()
  const { handleOnSearch } = useList()
  const [term, setTerm] = useState<Record<string, string>>({})
  const debouncedTerm = useDebounce<Record<string, string>>(term, 500)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleOnSearch(debouncedTerm), [debouncedTerm])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRight: (theme: Theme) => theme.content.border,
      }}
    >
      <SearchIcon />
      <TextField
        id="search"
        variant="standard"
        type="search"
        placeholder={placeholder}
        defaultValue={query?.[searchFor]}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setTerm({ [searchFor]: e.target.value })
        }
        sx={{
          width: 300,
        }}
      />
    </Box>
  )
}
