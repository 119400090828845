import * as yup from 'yup'

import { ImportExternalTokenToCollectionMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

const ON_CHAIN_ID = 'onChainId'
const CONTRACT_ADDRESS = 'contractAddress'

export const importExternalTokenToCollectionMutationValidation = yup.object<
  Shape<ImportExternalTokenToCollectionMutationVariables>
>({
  onChainId: yup
    .number()
    .required(`${ON_CHAIN_ID} is required.`)
    .min(1, 'Min length is 1.'),
  contractAddress: yup
    .string()
    .required(`${CONTRACT_ADDRESS} is required.`)
    .min(1, 'Min length is 1.'),
  marketplaceId: yup.string().required('Marketplace ID is required.'),
})
