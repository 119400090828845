import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const NFTIcon = memo(
  createSvgIcon(
    <path d="M5.80408 5.28029L2.1246 10.3396C1.94067 10.5943 1.96281 10.9773 2.17489 11.2092L11.8335 21.7876C12.0913 22.0708 12.5963 22.0708 12.854 21.7876L22.5127 11.2092C22.7247 10.9773 22.7469 10.5943 22.563 10.3396L18.8835 5.28029C18.7547 5.10581 18.5398 4.99835 18.3229 5.00003H6.36462C6.12805 4.99773 5.93304 5.12306 5.80408 5.28029ZM7.37073 6.37984H10.8921L8.5062 9.29755L7.37073 6.37984ZM13.8026 6.37984H17.3097L16.1814 9.29035L13.8026 6.37984ZM12.3366 6.77508L15.0243 10.0593H9.65603L12.3366 6.77508ZM6.18497 7.11286L7.32761 10.0593H4.04338L6.18497 7.11286ZM18.5026 7.11286L20.6442 10.0593H17.3671L18.5026 7.11286ZM4.2518 11.4391H7.85941L10.5256 18.3094L4.2518 11.4391ZM9.33263 11.4391H15.3477L12.3438 19.1861L9.33263 11.4391ZM16.8281 11.4391H20.4358L14.1691 18.3022L16.8281 11.4391Z" />,
    'NFTIcon'
  )
)
