import Box from '@mui/material/Box'

import { PageHead } from './PageHead'
import { PageHeader } from './PageHeader'

interface PageWrapperProps {
  children: React.ReactNode
  headerTitle: string
  toolbarTitle?: string
  toolbarBadge?: number
}

export const PageWrapper: React.FunctionComponent<PageWrapperProps> = ({
  children,
  headerTitle,
  toolbarTitle,
  toolbarBadge,
}) => (
  <>
    <PageHead title={headerTitle} />
    {(toolbarTitle || toolbarBadge) && (
      <PageHeader title={toolbarTitle} badgeContent={toolbarBadge} />
    )}
    <Box
      display="flex"
      flexGrow={1}
      height="100%"
      flexDirection={['column', 'row']}
      overflow="auto"
      className="PageWrapper"
    >
      {children}
    </Box>
  </>
)
