import * as yup from 'yup'

import { OrgCreateMarketplaceMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

export const orgCreateMarketplaceMutationValidation = yup.object<
  Shape<OrgCreateMarketplaceMutationVariables>
>({
  name: yup.string().required(`name is required.`).min(3, 'Min length is 3.'),
  orgId: yup.string().required('Org ID is required.'),
})
