import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const EthereumIcon = memo(
  createSvgIcon(
    <path d="M6.20169 0L0 9.7026L7.01389 13.1507L13.9161 9.69958L7.82458 0H7.39734H6.20169ZM6.23792 2.81099V6.17452L3.21256 7.5468L6.23792 2.81099ZM7.78382 2.84269L10.7352 7.54227L7.78382 6.18659V2.84269ZM6.23792 7.87138V11.0462L2.88043 9.39463L6.23792 7.87138ZM7.78382 7.88949L11.0658 9.39614L7.78382 11.0371V7.88949ZM0.0543478 11.2168V13.1643L6.18207 20H7.01087H7.83967L13.9674 13.1643V11.2168L7.01087 14.7826L0.0543478 11.2168ZM3.54921 14.7464L6.23792 16.1247V17.7446L3.54921 14.7464ZM10.4725 14.7464L7.78382 17.7446V16.1247L10.4725 14.7464Z" />,
    'EthereumIcon'
  )
)
