import { ApolloError } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import Box from '@mui/material/Box'

import { config } from '@constants/general.constants'
import { Boundary } from '@shared'

// TODO: Build UI.
export const ErrorMessage: React.FC<{ error: ApolloError }> = ({ error }) => {
  const { logout } = useAuth0()
  //for jwt token invalid
  if (error.message == 'jwt token invalid or has been expired') {
    logout({
      returnTo: config.AUTH_REDIRECT_URI,
    })
    alert('Your session has been expired. Please login to continue.')
    return <Boundary isLoading={true} />
  } else {
    //For Appollo Error
    return (
      <Box
        display="flex"
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <code>{error.message}</code>
      </Box>
    )
  }
}
