import React, { memo } from 'react'
import Image from 'next/image'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import Loader from '@assets/mojito-loader.gif'
interface TableLoaderProps {
  message?: string
  colSpan?: number
}

export const TableLoader = memo(function MemorizedLoader({
  message,
  colSpan,
}: TableLoaderProps) {
  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        align="center"
        colSpan={colSpan ?? 1}
      >
        {message ? (
          <Typography>{message}</Typography>
        ) : (
          <Image unoptimized={true} src={Loader} width={50} height={50} />
        )}
      </TableCell>
    </TableRow>
  )
})
