import { createSvgIcon } from '@mui/material'

export const TransfersIcon = createSvgIcon(
  <>
    <path
      d="M2.5 5.8335L5.83333 9.16683"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 5.83333L5.83333 2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 14.1665L14.1667 17.4998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 14.1668L14.1667 10.8335"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 5.8335H2.91669"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M7.5 14.1665H17.0833"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </>,
  'TransfersIcon'
)
