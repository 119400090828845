import { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

import { config } from '@constants/general.constants'
import { EColor, EShadow } from '@types'
import { TextBlock } from './TextBlock'

export const AccountMenu: React.FC = () => {
  const { logout, user } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = useCallback(
    () =>
      logout({
        returnTo: config.AUTH_REDIRECT_URI,
      }),
    [logout]
  )

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
        <Hidden smDown>
          <Box mr={2}>
            <TextBlock
              label="Hey,"
              text={user?.given_name}
              sx={{ marginBottom: 0 }}
              asList
            />
          </Box>
        </Hidden>
        <Tooltip title="User menu">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 42, height: 42 }} src={user?.picture} />
            <StyledIcon
              fontSize="small"
              sx={{
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: `drop-shadow(${EShadow.Thick})`,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& .MuiMenuItem-root': {
              paddingX: 2,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

const StyledIcon = styled(KeyboardArrowDown)({
  color: EColor.Gray900,
  transition: '0.2s',
  mr: -1,
})
