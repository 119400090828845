import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

import { EColor } from '@types'

export const GridViewIcon = memo(
  createSvgIcon(
    <>
      <rect width="7.77778" height="7.77778" rx="2" fill={EColor.Gray800} />
      <rect
        y="12.2222"
        width="7.77778"
        height="7.77778"
        rx="2"
        fill={EColor.Gray800}
      />
      <rect
        x="12.2222"
        width="7.77778"
        height="7.77778"
        rx="2"
        fill={EColor.Gray800}
      />
      <rect
        x="12.2222"
        y="12.2222"
        width="7.77778"
        height="7.77778"
        rx="2"
        fill={EColor.Gray800}
      />
    </>,
    'GridViewIcon'
  )
)
