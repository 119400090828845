import React, { FunctionComponent, memo } from 'react'

import Grid from '@mui/material/Grid'

interface ListItemProps {
  children: React.ReactNode
}

export const ListItem: FunctionComponent<ListItemProps> = ({ children }) => {
  return (
    <Grid item xs={12} sm={6} xl={4} width="100%">
      {children}
    </Grid>
  )
}

export default memo(ListItem)
