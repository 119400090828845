import { CSSProperties, forwardRef, MouseEventHandler } from 'react'

declare type Url = string | undefined
const Anchor = forwardRef<
  HTMLAnchorElement,
  {
    style?: CSSProperties
    children?: React.ReactNode
    href?: Url
    onClick?: MouseEventHandler<HTMLAnchorElement>
  }
>(({ style, children, href, onClick }, ref) => (
  <a
    ref={ref}
    style={{ textDecoration: 'none', ...style }}
    href={href}
    onClick={onClick}
  >
    {children}
  </a>
))
Anchor.displayName = 'Anchor'
export { Anchor }
