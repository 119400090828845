import { memo } from 'react'

import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { EColor } from '@types'
import { AccountMenu } from './AccountMenu'
interface PageHeaderProps {
  title?: string
  badgeContent?: number
}

export const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  badgeContent,
}) => {
  return (
    <StyledToolbar>
      <StyledHeader variant="h2" title={title}>
        {title}
        {!!badgeContent && <StyledBadge badgeContent={badgeContent} />}
      </StyledHeader>
      <AccountMenu />
    </StyledToolbar>
  )
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 22px',
  background: theme.palette.common.white,
  border: `1px solid ${EColor.Gray200}`,
  height: theme.navigation.toolbarHeight,
  flexShrink: 0,
}))

export const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  lineHeight: '31px',
  fontWeight: 600,
  fontFamily: theme.fonts.secondary,
  color: theme.palette.common.black,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  ...theme.content.ellipsis,
}))

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -14,
    top: -8,
    border: `2px solid ${theme.colors.secondary.pink}`,
    padding: '0 4px',
    background: theme.colors.secondary.pink,
  },
}))

export default memo(PageHeader)
