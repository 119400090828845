import { keyframes } from '@emotion/react'

export const animations = {
  pulsing: keyframes`
    0% {
      opacity: 0
    }
    50% {
      opacity: 1
    }
    100% {
      opacity: 0
    }`,
}
