import React, { memo, MouseEvent, useMemo, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { localStorageKeys } from '@constants/general.constants'
import { useMeQuery } from '@services/graphql/generated'
import { EColor, SavedRoute } from '@types'

const EnvironmentLabel = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 15,
      right: 20,
      zIndex: (theme: Theme) => theme.zIndex.drawer + 2,
      py: 0.25,
      px: 1,
      borderRadius: 1,
      backgroundColor: (theme: Theme) => theme.colors.primary.green,
    }}
  >
    <Typography
      fontSize={10}
      fontFamily={(theme: Theme) => theme.fonts.secondary}
      textTransform="uppercase"
      lineHeight="11px"
    >
      {process.env.NEXT_PUBLIC_APP_ENV}
    </Typography>
  </Box>
)

const OrganizationMenu = () => {
  const router = useRouter()
  const { organization } = router.query
  const { data } = useMeQuery()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOrganization, setSelectedOrganization] = useState(
    organization as string
  )

  const organizations = useMemo(() => data?.me?.userOrgs, [data])

  const open = Boolean(anchorEl)

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    handle: string,
    e: MouseEvent<HTMLLIElement>
  ) => {
    const firstOrgMarketplaceId = findFirstOrgMarketplaceId(handle)

    if (!(e.ctrlKey || e.metaKey)) {
      setSelectedOrganization(handle)
    }

    setAnchorEl(null)
    const route: Partial<SavedRoute> = {
      userId: data?.me?.user.id,
      handle,
      marketplaceID: firstOrgMarketplaceId,
    }
    localStorage.setItem(localStorageKeys.SavedRoute, JSON.stringify(route))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const findFirstOrgMarketplaceId = (handle: string) =>
    organizations?.find(org => org?.organization?.handle === handle)
      ?.organization.marketplaces?.[0]?.id

  const selectedOrganizationName = useMemo(
    () =>
      organizations?.filter(
        org => org.organization.handle === selectedOrganization
      )?.[0]?.organization.name ?? 'Select Organization',
    [organizations, selectedOrganization]
  )

  return (
    <Box width="100%" height="100%">
      {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && <EnvironmentLabel />}
      <StyledList open={open} onClick={handleClickListItem}>
        <ListItem
          button
          id="organization-menu"
          aria-haspopup="listbox"
          aria-controls="organization-menu"
          aria-label="Organization"
          aria-expanded={open ? 'true' : undefined}
          sx={{ px: 2.5, py: 2 }}
        >
          <StyledListItemText
            primary="Organization"
            secondary={selectedOrganizationName}
          />
          <StyledIcon
            fontSize="small"
            sx={{
              mb: -2,
              transform: open ? 'rotate(-180deg)' : 'rotate(0)',
              transition: '0.2s',
            }}
          />
        </ListItem>
      </StyledList>
      <Menu
        id="organization-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'organization-button',
          role: 'listbox',
        }}
        variant="menu"
      >
        {organizations?.map(({ organization }) => (
          <MenuItem
            key={organization.handle}
            selected={organization.handle === selectedOrganization}
            onClick={e => handleMenuItemClick(organization.handle, e)}
          >
            <Link
              href={`/${organization.handle}/${
                findFirstOrgMarketplaceId(organization.handle) ?? ''
              }`}
            >
              <a>{organization.name}</a>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '14px',
    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    opacity: '0.4',
    color: theme.palette.common.white,
    fontFamily: theme => theme.fonts.secondary,
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '29px',
    marginTop: 2,
    ...theme.content.ellipsis,
  },
}))

const StyledIcon = styled(KeyboardArrowDown)(({ theme }) => ({
  color: theme.palette.common.white,
}))

const StyledList = styled(List)(({ open }: { open: boolean }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  height: '100%',
  '& .MuiListItem-button': {
    background: open ? EColor.Dark900 : 'inherit',
    height: '100%',
    '&:hover': {
      background: EColor.Dark900,
    },
    '& .MuiTypography-body1': {
      marginLeft: 0,
    },
  },
}))

export default memo(OrganizationMenu)
