import {
  AuctionLotStatus,
  InvoiceStatus,
  MarketCollectionStatus,
  TransferStatus,
} from '@services/graphql/generated'

type Status =
  | MarketCollectionStatus
  | AuctionLotStatus
  | InvoiceStatus
  | TransferStatus

export const getChipColor = (status: Status) => {
  switch (status) {
    case TransferStatus.Queued:
      return 'warning'

    case MarketCollectionStatus.Inactive:
    case InvoiceStatus.Draft:
    case InvoiceStatus.AwaitingUserPayment:
    case TransferStatus.InProgress:
      return 'info'

    case AuctionLotStatus.Hidden:
    case AuctionLotStatus.Preview:
    case AuctionLotStatus.Completed:
      return 'secondary'

    case InvoiceStatus.Pending:
      return 'warning'

    case InvoiceStatus.Canceled:
    case InvoiceStatus.Expired:
    case InvoiceStatus.Failed:
    case TransferStatus.Failed:
    case TransferStatus.Canceled:
      return 'error'

    case InvoiceStatus.Paid:
    case TransferStatus.Success:
    case MarketCollectionStatus.Active:
    case InvoiceStatus.Delivered:
      return 'success'

    default:
      return 'default'
  }
}
