import { FunctionComponent } from 'react'
import Link from 'next/link'

import Card, { CardProps } from '@mui/material/Card'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

import { Anchor } from '@shared'
import { EColor } from '@types'

interface CustomCardProps extends CardProps {
  link?: string
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  sx?: SxProps<Theme>
}

export const CustomCard: FunctionComponent<CustomCardProps> = ({
  children,
  link,
  onClick,
  sx,
  ...props
}) => {
  const content = (
    <Card
      {...props}
      elevation={0}
      onClick={onClick}
      onTouchStart={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
      variant="elevation"
      sx={{
        ...sx,
        ...(link || onClick
          ? {
              ':hover': {
                border: `2px solid ${EColor.Green}`,
              },
            }
          : { cursor: 'default' }),
      }}
    >
      {children}
    </Card>
  )

  if (link) {
    return (
      <Link href={link} passHref>
        <Anchor
          onClick={(event?: React.MouseEvent<HTMLElement>) =>
            event?.stopPropagation()
          }
        >
          {content}
        </Anchor>
      </Link>
    )
  }

  return content
}
