import CardContent from '@mui/material/CardContent'
import { styled, Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

import { useList } from './List/ListContext'

interface CustomCardContentProps {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export const CustomCardContent: React.FC<CustomCardContentProps> = ({
  children,
  sx,
}) => {
  const { asGrid } = useList()

  const sharedSx = {
    borderRadius: 1,
  }

  const asGridSx = {
    ...sharedSx,
    padding: 2.5,
  }

  const asListSx = {
    ...sharedSx,
    padding: 1.25,
    minHeight: 87,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(50px,1fr))',
    '& svg': {
      justifySelf: 'center',
    },
    alignItems: 'center',
    gap: ['10px', '20px'],
    ':last-child': {
      paddingBottom: 1.25,
    },
  }
  return (
    <StyledCardContent sx={{ ...(asGrid ? asGridSx : asListSx), ...sx }}>
      {children}
    </StyledCardContent>
  )
}

const StyledCardContent = styled(CardContent)({
  width: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'visible',
  '& .image': {
    borderRadius: '8px',
  },
})
