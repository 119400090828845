import React, { useRef } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { ApolloLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/nextjs'
import { createUploadLink } from 'apollo-upload-client'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { config } from '@constants/general.constants'

export function AuthorizedApolloProvider({ children }) {
  const { getIdTokenClaims } = useAuth0()

  const uploadLink = createUploadLink({
    uri: `${config.API_HOSTNAME}/query`,
  })

  const httpLink = createHttpLink({
    uri: `${config.API_HOSTNAME}/query`,
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message }) => Sentry.captureMessage(message))
    if (networkError) {
      Sentry.captureException(networkError)
    }
  })

  const ssrMode = typeof window === 'undefined'
  let link = httpLink

  const authLink = setContext(async (_, { headers }) => {
    try {
      const token = await getIdTokenClaims()
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token?.__raw}`,
        },
      }
    } catch (e) {}
  })

  if (!ssrMode) {
    const wsLink = new WebSocketLink(
      new SubscriptionClient(`${config.WSS_HOSTNAME}/query` || '', {
        reconnect: true,
        lazy: true,
        connectionParams: async () => {
          try {
            const token = await getIdTokenClaims()
            return {
              Authorization: `Bearer ${token?.__raw}`,
            }
          } catch (e) {}
        },
      })
    )

    link = ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      from([errorLink, authLink, uploadLink])
    )
  }

  const apolloClient = useRef(
    new ApolloClient({
      ssrMode,
      link,
      cache: new InMemoryCache({
        typePolicies: {
          WalletToken: {
            keyFields: ['contractAddress', 'id'],
          },
        },
      }),
      connectToDevTools: true,
    })
  )

  return (
    <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>
  )
}
