import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const SearchIcon = memo(
  createSvgIcon(
    <path d="M19.6595 18.0477L16.0726 14.4608C17.2304 12.9398 17.9342 11.0329 17.9342 8.96708C17.9342 4.01816 13.916 0 8.96708 0C4.01816 0 0 4.01816 0 8.96708C0 13.916 4.01816 17.9569 8.96708 17.9569C11.0329 17.9569 12.9398 17.2531 14.4608 16.0726L18.0477 19.6368C18.252 19.8638 18.5471 19.9773 18.8422 19.9773C19.1373 19.9773 19.4325 19.8638 19.6595 19.6595C20.1135 19.2054 20.1135 18.5017 19.6595 18.0477ZM2.27015 8.96708C2.27015 5.26674 5.26674 2.27015 8.96708 2.27015C12.6674 2.27015 15.664 5.26674 15.664 8.96708C15.664 12.6674 12.6674 15.664 8.96708 15.664C5.26674 15.664 2.27015 12.6674 2.27015 8.96708Z" />,
    'SearchIcon'
  )
)
