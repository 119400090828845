import React, { DetailedHTMLProps, FC, useCallback, useEffect } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { RegisterOptions, UseFormRegisterReturn } from 'react-hook-form'

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { EColor } from '@types'
import { formatBytes } from '@utils'

interface IFileInputProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  // TODO: Fix to correct types
  name: string
  register: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
  unregister: any
  setValue: any
  watch: any
}

export const FileInput: FC<IFileInputProps> = ({
  name,
  register,
  unregister,
  setValue,
  watch,
}) => {
  const files: File[] = watch(name)

  const onDrop = useCallback<NonNullable<DropzoneOptions['onDrop']>>(
    droppedFiles => {
      setValue(name, droppedFiles, { shouldValidate: true })
    },
    [setValue, name]
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  const removeFile = useCallback(
    (file: File) => {
      const newFiles = files.filter(_file => file.name !== _file.name)
      setValue(name, newFiles.length === 0 ? undefined : newFiles, {
        shouldValidate: true,
      })
    },
    [setValue, name, files]
  )

  const rootProps = files ? null : getRootProps()

  return (
    <Box
      {...rootProps}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2.5,
        borderWidth: 1,
        borderRadius: '5px',
        borderColor: EColor.Gray500,
        borderStyle: 'dashed',
        backgroundColor: (theme: Theme) => theme.colors.primary.white,
        color: EColor.Gray600,
        transition: 'border .3s ease-in-out',
        marginBottom: 0.5,
      }}
    >
      <input {...getInputProps()} />
      {!files?.length && (
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          <ArrowCircleUpIcon
            sx={{
              width: 16,
              height: 16,
              verticalAlign: 'middle',
              marginTop: '-1px',
              marginRight: 0.5,
            }}
          />
          Drag and Drop file here or{' '}
          <Typography
            variant="caption"
            color={EColor.Green}
            sx={{
              fontWeight: 500,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            choose file
          </Typography>
        </Typography>
      )}
      {files?.length > 0 &&
        files.map(file => (
          <Box
            key={file.name}
            display="flex"
            width="100%"
            justifyContent="space-between"
            mt={1}
          >
            <Typography
              variant="caption"
              display="flex"
              alignItems="center"
              sx={{ fontWeight: 500 }}
            >
              <UploadFileIcon
                sx={{
                  color: EColor.Green,
                  width: 16,
                  height: 16,
                  verticalAlign: 'middle',
                  marginTop: '-1px',
                  marginRight: 1,
                }}
              />
              {file?.name}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="caption"
                sx={{
                  fontSize: 8,
                  fontWeight: 500,
                  background: EColor.Neutral500,
                  borderRadius: 0.25,
                  color: EColor.Gray500,
                  padding: 0.5,
                }}
              >
                {formatBytes(file?.size)}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 0 }} />
              <CancelRoundedIcon
                onClick={() => removeFile(file)}
                sx={{
                  color: (theme: Theme) => theme.colors.tertiary.grey,
                  width: 16,
                  height: 16,
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Box>
        ))}
    </Box>
  )
}
