import { ENetworks } from '@types'

export const generateContractUrl = ({
  type,
  hash,
  chainId,
}: {
  type: string
  hash: string
  chainId?: number
}): string => {
  switch (chainId) {
    case 1:
      return `${ENetworks.etherscan}${type}/${hash}`
    case 5:
      return `${ENetworks.görli}${type}/${hash}`
    case 137:
      return `${ENetworks.polygonscan}${type}/${hash}`
    case 80001:
      return `${ENetworks.mumbai}${type}/${hash}`
    case 11155111:
      return `${ENetworks.sepolia}${type}/${hash}`
    default:
      return `${ENetworks.etherscan}${type}/${hash}`
  }
}
