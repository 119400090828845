import { ContractType, DeliveryMethod } from '@services/graphql/generated'

export const getDeliveryByContractType = (contractType: ContractType) => {
  switch (contractType) {
    case ContractType.Erc1155Creator:
      return DeliveryMethod.Erc1155Transfer
    case ContractType.Erc721Creator:
      return DeliveryMethod.Erc721Transfer
    default:
      return DeliveryMethod.NoOp
  }
}
