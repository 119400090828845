import * as yup from 'yup'

import { SetJwtIssuerDomainMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

export const setJwtIssuerDomainMutationValidation = yup.object<
  Shape<SetJwtIssuerDomainMutationVariables>
>({
  domain: yup.string().required(`domain is required.`),
  orgId: yup.string().required('Org ID is required.'),
})
