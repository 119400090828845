import * as yup from 'yup'

import {
  DeliveryMethod,
  MarketplaceAuctionLot,
  MarketplaceItemDeliveryErc721TransferInput,
  MarketplaceItemDeliveryErc1155TransferInput,
  MarketplaceItemDeliveryInput,
  MarketplaceItemDeliveryNoOpInput,
  UpdateMarketplaceAuctionLotMutationVariables,
} from '@services/graphql/generated'
import { Shape } from '@types'

export const updateMarketplaceAuctionLotMutationValidation = (
  lot: MarketplaceAuctionLot,
  notAvailableLotNumbers: number[]
) =>
  yup.object<Shape<UpdateMarketplaceAuctionLotMutationVariables>>({
    status: yup.string(),
    startDate: yup.date(),
    endDate: yup.date(),
    startingBid: yup.lazy(value =>
      value === '' ? yup.string() : yup.number().positive()
    ),
    lotNumber: yup.lazy(value => {
      if (value === '') return yup.string()
      if (value !== lot.lotNumber)
        return yup
          .number()
          .notOneOf(
            notAvailableLotNumbers,
            'Lot number is already assigned. Enter a different Lot Number'
          )
      return yup.number()
    }),
    delivery: yup.object<Shape<MarketplaceItemDeliveryInput>>({
      deliveryMethod: yup.string().notRequired(),
      ERC721Provenance: yup.object().notRequired(),
      ERC1155OpenEdition: yup.object().notRequired(),
      ERC721TransferByRange: yup.object().notRequired(),
      ERC1155Provenance: yup.object().notRequired(),
      ERC1155Transfer: yup
        .object()
        .notRequired()
        .when('deliveryMethod', {
          is: (deliveryMethod: DeliveryMethod) => {
            if (deliveryMethod === DeliveryMethod.Erc1155Transfer) {
              return true
            }
            return false
          },
          then: yup.object<Shape<MarketplaceItemDeliveryErc1155TransferInput>>({
            amount: yup.string().notRequired().nullable().default(null),
            contractAddress: yup
              .string()
              .required('ContractAddress is required'),
            ownerWalletId: yup.string().required('OwnerWalletId is required'),
            onChainTokenId: yup.number().required('OnChainTokenId is required'),
          }),
        }),

      ERC721Transfer: yup
        .object()
        .notRequired()
        .when('deliveryMethod', {
          is: (deliveryMethod: DeliveryMethod) => {
            if (deliveryMethod === DeliveryMethod.Erc721Transfer) {
              return true
            }
            return false
          },
          then: yup.object<Shape<MarketplaceItemDeliveryErc721TransferInput>>({
            contractAddress: yup
              .string()
              .required('ContractAddress is required'),
            ownerWalletId: yup.string().required('OwnerWalletId is required'),
            onChainTokenId: yup.number().required('OnChainTokenId is required'),
            randomizeTokensInAddress: yup
              .array()
              .notRequired()
              .nullable()
              .default(null),
            RelayTxInfo: yup.object().notRequired().nullable().default(null),
          }),
        }),
      NoOp: yup
        .object()
        .notRequired()
        .when('deliveryMethod', {
          is: (deliveryMethod: DeliveryMethod) => {
            if (deliveryMethod === DeliveryMethod.NoOp) {
              return true
            }
            return false
          },
          then: yup.object<Shape<MarketplaceItemDeliveryNoOpInput>>({
            notes: yup.string().required('Notes is required'),
            noOpcontractAddress: yup
              .string()
              .required('Contract Address is required'),
            noOpOnChainTokenId: yup
              .number()
              .required('OnChainTokenId is required'),
          }),
        }),
    }),
    marketplaceAuctionLotId: yup.string().required('Lot ID is required'),
  })
