import { COLLECTION_DATE_FORMAT, dayjs } from '../dayjsUtils'

export const dateLabelValue = (
  startDate: string,
  endDate: string,
  format?: string
) => {
  const started = dayjs().isAfter(dayjs(startDate))
  const ended = dayjs().isAfter(dayjs(endDate))

  return {
    dateLabel: ended ? 'Concluded' : started ? 'Ends' : 'Scheduled',
    dateValue: dayjs(ended || started ? endDate : startDate).format(
      format ?? COLLECTION_DATE_FORMAT
    ),
  }
}
