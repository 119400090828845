import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const MintIcon = memo(
  createSvgIcon(
    <>
      <path
        d="M6.43407 5.44208L9.15236 5.75508C12.0417 6.08777 14.5357 7.50861 16.1511 9.68733C17.7665 11.8661 18.4013 14.6654 17.8801 17.5267L17.3898 20.2187L14.6715 19.9057C11.7783 19.5725 9.35505 18.2425 7.67269 15.9734C5.99032 13.7043 5.42182 10.9992 5.94372 8.13404L6.43407 5.44208ZM18.4672 21.9588L18.6998 21.9856L18.7417 21.7552L19.4596 17.8144C20.056 14.5403 19.3348 11.2857 17.4408 8.73116C15.5467 6.17657 12.6422 4.54086 9.33601 4.16016L5.35664 3.70196L5.12405 3.67517L5.08209 3.90551L4.36426 7.84633C3.76894 11.1146 4.4164 14.2771 6.38303 16.9296C8.34966 19.5821 11.1876 21.1206 14.4878 21.5006L18.4672 21.9588Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <line
        x1="6.5342"
        y1="4.93271"
        x2="17.8912"
        y2="21.0578"
        stroke="currentColor"
        strokeWidth="1.5s"
      />
      <line
        x1="13.467"
        y1="6.5159"
        x2="14.5482"
        y2="16.525"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        x1="5.03151"
        y1="11.859"
        x2="14.2059"
        y2="15.8379"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </>,
    'MintIcon'
  )
)
