import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

import { EColor } from '@types'

export const ListViewIcon = memo(
  createSvgIcon(
    <>
      <rect y="14" width="20" height="3" rx="1.5" fill={EColor.Dark500} />
      <rect width="20" height="3" rx="1.5" fill={EColor.Dark500} />
      <rect y="7" width="20" height="3" rx="1.5" fill={EColor.Dark500} />
    </>,
    'ListViewIcon'
  )
)
