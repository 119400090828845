import * as yup from 'yup'

import { Shape } from '@types'
import { withMinDateErrorMessage, withRequiredErrorMessage } from '@utils'
import { IdentifierType } from '../services/graphql/generated'

export interface AllowListFormData {
  allowList: string
  identifierType: IdentifierType
  startTime: Date
  endTime: Date
  elements: string
  name: string
  description: string
}

export const ALLOW_LIST = 'allowList'
export const IDENTIFIER_TYPE = 'identifierType'
export const START_TIME = 'startTime'
export const END_TIME = 'endTime'
export const ELEMENTS = 'elements'
export const NAME = 'name'
export const DESCRIPTION = 'description'

export const ALLOW_LIST_LABEL = 'Allow List'
export const IDENTIFIER_TYPE_LABEL = 'Identifier Type'
export const START_TIME_LABEL = 'Start Time'
export const END_TIME_LABEL = 'End Time'
export const ELEMENTS_LABEL = 'Elements'

export const ALLOW_LIST_CREATE = '<CREATE>'
export const ALLOW_LIST_REMOVE = '<REMOVE>'

export const allowListValidation = yup.object<Shape<AllowListFormData>>({
  [ALLOW_LIST]: yup
    .string()
    .label(ALLOW_LIST_LABEL)
    .required(withRequiredErrorMessage),
  [IDENTIFIER_TYPE]: yup
    .string()
    .label(IDENTIFIER_TYPE_LABEL)
    .notRequired()
    .when('allowList', {
      is: ALLOW_LIST_CREATE,
      then: yup
        .string()
        .required(withRequiredErrorMessage)
        .oneOf(['ExternalUserID', 'UserID'], withRequiredErrorMessage),
    }),
  [START_TIME]: yup
    .date()
    .label(START_TIME_LABEL)
    .notRequired()
    .when('allowList', {
      is: ALLOW_LIST_CREATE,
      then: yup
        .date()
        .required(withRequiredErrorMessage)
        .min(new Date(), withMinDateErrorMessage('today')),
    }),
  [END_TIME]: yup
    .date()
    .label(END_TIME_LABEL)
    .notRequired()
    .when('allowList', {
      is: ALLOW_LIST_CREATE,
      then: yup
        .date()
        .required(withRequiredErrorMessage)
        .min(
          yup.ref<Date | undefined>('startTime', {
            map: (startTimeDate: Date | undefined) => {
              if (startTimeDate) {
                try {
                  // Valid for at least 1 minute:
                  return new Date(startTimeDate.getTime() + 60000)
                } catch (err) {
                  /* Ignore */
                }
              }

              return new Date()
            },
          }),
          withMinDateErrorMessage(START_TIME_LABEL)
        ),
    }),
  [ELEMENTS]: yup
    .string()
    .label(ELEMENTS_LABEL)
    .notRequired()
    .when(['allowList', 'identifierType'], {
      is: (allowList: string, identifierType: string) => {
        if (allowList == '<CREATE>' && identifierType == 'UserID') {
          return false
        } else if (
          allowList == '<CREATE>' &&
          identifierType == 'ExternalUserID'
        ) {
          return true
        }
      },
      then: yup
        .string()
        .required(withRequiredErrorMessage)
        .test(
          'are-elements-valid',
          'Invalid format, please enter at least 1 Auth0 id to continue. Separated by new entries. Spaces are not allowed.',
          value => (value ? /^[a-z-]*auth\d\|[0-9A-F]+$/gim.test(value) : true)
        ),
      otherwise: yup
        .string()
        .required(withRequiredErrorMessage)
        .test(
          'are-elements-valid',
          'Invalid format, please enter at least 1 User id to continue. Separated by new entries. Spaces are not allowed.',
          value => (value ? /^[\S\n]*$/.test(value) : false)
        ),
    }),
  [NAME]: yup
    .string()
    .label('Name')
    .required(withRequiredErrorMessage)
    .when('allowList', {
      is: ALLOW_LIST_CREATE,
      then: yup.string().required(withRequiredErrorMessage),
    }),
  [DESCRIPTION]: yup
    .string()
    .label('Description')
    .required(withRequiredErrorMessage)
    .when('allowList', {
      is: ALLOW_LIST_CREATE,
      then: yup.string().required(withRequiredErrorMessage),
    }),
})
