import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const ArrowRightIcon = memo(
  createSvgIcon(
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4528 10.4629L13.9823 7.02543L14.7602 6.24002L19.5748 11.0087L14.7639 15.8653L13.9786 15.0874L17.4644 11.5683L1.10712 11.6073L1.10449 10.5018L17.4528 10.4629Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="0.5"
    />,
    'ArrowRightIcon'
  )
)
