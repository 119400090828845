export const config = {
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_CLIENTID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
  AUTH_REDIRECT_URI: process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI,
  API_HOSTNAME: process.env.NEXT_PUBLIC_API_HOSTNAME,
  WSS_HOSTNAME: process.env.NEXT_PUBLIC_WSS_HOSTNAME,
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  INVOICE_URL: process.env.NEXT_PUBLIC_INVOICE_URL,
  DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
}

export const networkMap: Record<number, string> = {
  1: 'Mainnet',
  5: 'Gorli',
  137: 'Polygon Mainnet',
  80001: 'Mumbai Testnet',
  11155111: 'Sepolia Testnet',
}

export const onChainContractAddress = {
  1: process.env.NEXT_PUBLIC_CONTRACT_ETHEREUM,
  5: process.env.NEXT_PUBLIC_CONTRACT_GORELI,
  137: process.env.NEXT_PUBLIC_CONTRACT_ETHEREUM,
  80001: process.env.NEXT_PUBLIC_CONTRACT_MUMBAI,
  11155111: process.env.NEXT_PUBLIC_CONTRACT_SEPOLIA,
}

export const onChainAuctionAddress = {
  1: process.env.NEXT_PUBLIC_ONCHAIN_ETHEREUM,
  5: process.env.NEXT_PUBLIC_ONCHAIN_GORELI,
  137: process.env.NEXT_PUBLIC_ONCHAIN_ETHEREUM,
  80001: process.env.NEXT_PUBLIC_ONCHAIN_MUMBAI,
  11155111: process.env.NEXT_PUBLIC_ONCHAIN_SEPOLIA,
}

export const localStorageKeys = {
  SavedRoute: 'savedRoute',
}

export const DEFAULT_QUERY_LIMIT = 9

export const WALLET_ADDRESS_FORMAT = /^0x\w{40}$/
