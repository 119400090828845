import { createSvgIcon } from '@mui/material'

export const RoundArrowForwardIcon = createSvgIcon(
  <>
    <rect width="24" height="24" rx="12" fill="#E8FFF1" />
    <path
      d="M6.77001 12.6167H15.372L11.6139 16.3748C11.3136 16.6751 11.3136 17.168 11.6139 17.4683C11.9143 17.7686 12.3994 17.7686 12.6998 17.4683L17.7747 12.3934C17.8461 12.3221 17.9027 12.2375 17.9414 12.1443C17.98 12.0512 17.9999 11.9513 17.9999 11.8504C17.9999 11.7496 17.98 11.6497 17.9414 11.5566C17.9027 11.4634 17.8461 11.3788 17.7747 11.3075L12.7075 6.22488C12.6362 6.15359 12.5515 6.09703 12.4584 6.05845C12.3652 6.01986 12.2654 6 12.1645 6C12.0637 6 11.9639 6.01986 11.8707 6.05845C11.7776 6.09703 11.6929 6.15359 11.6216 6.22488C11.5503 6.29618 11.4938 6.38082 11.4552 6.47398C11.4166 6.56713 11.3967 6.66697 11.3967 6.7678C11.3967 6.86863 11.4166 6.96847 11.4552 7.06163C11.4938 7.15478 11.5503 7.23942 11.6216 7.31072L15.372 11.0765H6.77001C6.34645 11.0765 5.99991 11.423 5.99991 11.8466C5.99991 12.2702 6.34645 12.6167 6.77001 12.6167Z"
      fill="#1AB759"
    />
  </>,
  'RoundArrowForwardIcon'
)
