import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
interface DialogProps extends Omit<MUIDialogProps, 'title'> {
  children: React.ReactNode
  title?: string | React.ReactNode
  onClose?: () => void
  open: boolean
  action?: () => void
  actionLabel?: string
  withCancel?: boolean
  cancelLabel?: string
  loading?: boolean
  sx?: SxProps<Theme>
  divider?: boolean
  disabledAction?: boolean
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  onClose,
  open,
  title,
  action,
  actionLabel,
  withCancel,
  cancelLabel,
  loading,
  sx,
  divider = true,
  maxWidth,
  fullWidth,
  disabledAction,
}) => (
  <MUIDialog
    open={open}
    onClose={onClose}
    {...(sx && { sx })}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
  >
    {onClose && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 13,
          top: 11,
          color: (theme: Theme) => theme.colors.tertiary.grey,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    )}
    <DialogTitle>{title}</DialogTitle>
    {divider && (
      <Divider
        sx={{
          marginTop: '10px',
          opacity: 0.3,
          background: (theme: Theme) => theme.colors.other.border,
        }}
      />
    )}
    <DialogContent>{children}</DialogContent>
    {action && actionLabel && (
      <DialogActions>
        <Button
          variant="containedGreen"
          onClick={action}
          disabled={disabledAction || loading}
        >
          {loading ? 'Loading...' : actionLabel ?? 'Next'}
        </Button>
        {withCancel && cancelLabel && (
          <Button variant="containedPink" onClick={onClose} disabled={loading}>
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    )}
  </MUIDialog>
)
