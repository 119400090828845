import {
  GetCollectionQuery,
  MarketplaceCollection,
  MarketplaceSaleType,
} from '@services/graphql/generated'

export const getNotAvailableLotNumbers = (
  collection: GetCollectionQuery['collection'] | MarketplaceCollection
) => {
  if (!collection?.items) {
    return []
  }

  const values: number[] = []

  //TODO: use reducer instead
  collection?.items
    .filter(item => item?.saleType === MarketplaceSaleType.Auction)
    .forEach(item => {
      if (
        item.details.__typename === 'MarketplaceAuctionLot' &&
        !!item.details.lotNumber
      ) {
        return values.push(item.details.lotNumber)
      }
    })

  return values.sort((a, b) => a - b)
}
