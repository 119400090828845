import { MessageParams } from 'yup/lib/types'

import { COLLECTION_DATE_FORMAT, dayjs } from '../dayjsUtils'

export type RequiredErrorMessageParams = Partial<MessageParams> & {
  label: string
}

export type MinDateErrorMessageParams = RequiredErrorMessageParams & {
  min: Date | string
}

export const withRequiredErrorMessage = ({
  label,
}: RequiredErrorMessageParams) => `${label} is required.`

export const withMinDateErrorMessage =
  (minLabel: string) =>
  ({ label, min }: MinDateErrorMessageParams) =>
    `${label} must be after ${minLabel} (${dayjs(min).format(
      COLLECTION_DATE_FORMAT
    )}).`
