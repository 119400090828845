import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

interface AsideProps {
  children?: React.ReactNode
  labelFor?: string
}

export const Aside: React.FunctionComponent<AsideProps> = ({
  children,
  labelFor,
}) => {
  if (!children) {
    return (
      <StyledAside component="aside">
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <StyledH6 variant="h6">It’s empty now!</StyledH6>
          <StyledSpan variant="body1">
            {`Select any ${labelFor} to get information`}
          </StyledSpan>
        </Box>
      </StyledAside>
    )
  }

  return <StyledAside component="aside">{children}</StyledAside>
}

const StyledH6 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 13.2154,
  lineHeight: '17px',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: theme.fonts.secondary,
}))

const StyledSpan = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '20px',
  textAlign: 'center',
  opacity: 0.5,
  marginTop: 5,
}))

const StyledAside = styled(Box)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  [theme.breakpoints.up('sm')]: {
    width: theme.aside.width,
    borderLeft: '1px solid #F3F3F3',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '20vh',
  },
}))
