import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const PlussIcon = memo(
  createSvgIcon(
    <path d="M10.3245 0C9.66663 0 9.13324 0.533362 9.13324 1.19129V3.35151C9.13324 6.54467 6.54467 9.13324 3.3515 9.13324H1.19129C0.533342 9.13324 0 9.66659 0 10.3245C0 10.9824 0.533342 11.5158 1.19129 11.5158H3.35151C6.54467 11.5158 9.13324 14.1044 9.13324 17.2976V19.4578C9.13324 20.1157 9.66663 20.6491 10.3245 20.6491C10.9825 20.6491 11.5158 20.1157 11.5158 19.4578V17.2976C11.5158 14.1044 14.1044 11.5158 17.2976 11.5158H19.4578C20.1157 11.5158 20.6491 10.9824 20.6491 10.3245C20.6491 9.66659 20.1157 9.13324 19.4578 9.13324H17.2976C14.1044 9.13324 11.5158 6.54467 11.5158 3.3515V1.19129C11.5158 0.533362 10.9825 0 10.3245 0Z" />,
    'PlussIcon'
  )
)
