import { useEffect, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import useCopyToClipboard from '@hooks/useCopyToClipboard'
import { EColor } from '@types'
import { CopyIcon } from './Icons'

type FontSize = 'inherit' | 'large' | 'medium' | 'small'

interface CopyClipboardButtonProps {
  label?: string
  value: string
  disabled?: boolean
  plain?: boolean
  fontSize?: FontSize
  iconColor?: string
}

export const CopyClipboardButton = ({
  label,
  value,
  disabled,
  plain,
  fontSize,
  iconColor,
}: CopyClipboardButtonProps) => {
  const [, copy] = useCopyToClipboard()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => handleTooltipClose(), 1000)
    return () => clearTimeout(timer)
  }, [open])

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      disableInteractive={disabled}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="top"
      title="Copied to Clipboard"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: EColor.Pink,
            color: EColor.Dark500,
          },
        },
      }}
    >
      {plain ? (
        <IconButton
          sx={{
            p: 0,
            height: 'fit-content',
            alignSelf: 'center',
            marginLeft: '5px',
          }}
          onTouchStart={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            copy(value as string)
            handleTooltipOpen()
          }}
        >
          <CopyIcon fontSize={fontSize} htmlColor={iconColor} />
        </IconButton>
      ) : (
        <Button
          variant="containedPink"
          sx={{
            px: 1,
            ml: 1,
            minWidth: 'fit-content',
          }}
          onTouchStart={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            copy(value)
            handleTooltipOpen()
          }}
          disabled={!!disabled}
        >
          {label && (
            <Typography
              component="span"
              fontSize={10}
              lineHeight={'11px'}
              sx={{ textDecoration: 'none', textTransform: 'capitalize' }}
            >
              {label}
            </Typography>
          )}
          <ContentCopyIcon sx={{ fontSize: 14, marginLeft: label ? 1 : 0 }} />
        </Button>
      )}
    </Tooltip>
  )
}
