import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const EditIcon = memo(
  createSvgIcon(
    <path d="M15.3179 0.847646C16.3886 -0.223065 18.0895 -0.279418 19.2266 0.678586L19.4107 0.847646L23.1524 4.58934C24.2231 5.66005 24.2794 7.361 23.3214 8.49805L23.1524 8.68213L7.83449 24H0V16.1655L15.3179 0.847646ZM13.0218 7.23366L2.89404 17.364V21.106H6.63315L16.7638 10.9757L13.0218 7.23366ZM17.3643 2.89518L15.0693 5.18757L18.8098 8.92813L21.106 6.63573L17.3643 2.89518Z" />,
    'EditIcon'
  )
)
