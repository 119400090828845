import { memo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import ApartmentIcon from '@mui/icons-material/Apartment'
import CategoryIcon from '@mui/icons-material/Category'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import FilterIcon from '@mui/icons-material/Filter'
import ListAltIcon from '@mui/icons-material/ListAlt'
import StoreIcon from '@mui/icons-material/Store'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled, Theme } from '@mui/material/styles'

import CreateOrganizationDialog from '@components/dialogs/CreateOrganizationDialog'
import useMojitoUser from '@hooks/useMojitoUser'
import useToggle from '@hooks/useToggle'
import {
  Anchor,
  CustomersIcon,
  NFTIcon,
  SettingsIcon,
  TransfersIcon,
} from '@shared'
import { EColor, EPages } from '@types'

interface NavigationOptionProps {
  title: string
  disabled?: boolean
  href: string
  logo: string
  selected: boolean
}

const NavigationOption = memo(function NavigationOptionFunc({
  title,
  disabled,
  href,
  selected,
  logo,
}: NavigationOptionProps) {
  const router = useRouter()
  const listItem = (
    <ListItemButton
      onClick={() => {
        router.push(href)
      }}
      selected={selected}
      disabled={disabled ?? false}
    >
      <ListItemIcon>{CustomIcon(logo)}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  )

  if (!selected) {
    return (
      <Link href={{ pathname: disabled ? '' : href }} passHref>
        <Anchor
          style={{
            textDecoration: 'none',
            ...(disabled && { pointerEvents: 'none', cursor: 'default' }),
          }}
        >
          {listItem}
        </Anchor>
      </Link>
    )
  }
  return listItem
})

const Navigation = () => {
  const [isOrganizationDialogOpen, toggleOrganizationDialog] = useToggle(false)
  const router = useRouter()
  const {
    asPath,
    query: { organization, marketplace, collectionID },
  } = router
  const { isMojitoUser } = useMojitoUser()

  const BASE_PATH = `/${organization}`
  const TOP_NAVIGATION_OPTIONS = [
    {
      href: `/${organization}/${marketplace}${EPages.collections}`,
      title: 'Sales',
      logo: 'store',
      disabled: !organization || !marketplace,
      selected: asPath.includes(collectionID as string),
    },
    {
      href: `/${organization}/${marketplace}${EPages.nfts}`,
      title: 'NFTs',
      logo: 'nft',
      disabled: !organization || !marketplace,
      selected: asPath.includes(EPages.nfts),
    },
  ]

  if (isMojitoUser) {
    TOP_NAVIGATION_OPTIONS.push({
      href: `/${organization}/${marketplace}${EPages.transfers}`,
      title: 'Transfers',
      logo: 'transfers',
      disabled: !organization || !marketplace,
      selected: asPath.includes(EPages.transfers),
    })
  }

  // TOP_NAVIGATION_OPTIONS.push({
  //   href: `/${organization}/${marketplace}${EPages.campaigns}`,
  //   title: 'Campaigns',
  //   logo: 'campaigns',
  //   disabled: !organization || !marketplace,
  //   selected: asPath.includes(EPages.campaigns),
  // })
  TOP_NAVIGATION_OPTIONS.push({
    href: `/${organization}/${marketplace}${EPages.listings}`,
    title: 'Listing Registry',
    logo: 'listings',
    disabled: !organization || !marketplace,
    selected:
      asPath.includes(EPages.listings) ||
      asPath.includes(EPages.registry) ||
      asPath.includes(EPages.registry_edit),
  })
  TOP_NAVIGATION_OPTIONS.push({
    href: `/${organization}/${marketplace}${EPages.artists}`,
    title: 'Artists',
    logo: 'Artist',
    disabled: !organization || !marketplace,
    selected: asPath.includes(EPages.artists),
  })
  TOP_NAVIGATION_OPTIONS.push({
    href: `/${organization}/${marketplace}${EPages.category}`,
    title: 'Category',
    logo: 'Category',
    disabled: !organization || !marketplace,
    selected: asPath.includes(EPages.category),
  })
  const BOTTOM_NAVIGATION_OPTIONS = [
    {
      href: `${BASE_PATH}${EPages.customers}`,
      title: 'Customers',
      logo: 'customer',
      disabled: !organization,
      selected: asPath.includes(EPages.customers),
    },
    {
      href: `${BASE_PATH}${EPages.settings}`,
      title: 'Settings',
      logo: 'setting',
      selected: asPath.includes(EPages.settings),
    },
    {
      href: `${BASE_PATH}${EPages.wallets}`,
      title: 'Wallets',
      logo: 'accountBalanceWallet',
      disabled: !organization,
      selected: asPath.includes(EPages.wallets),
    },
    {
      href: `${BASE_PATH}${EPages.assets}`,
      title: 'Assets',
      logo: 'filter',
      disabled: !organization,
      selected: asPath.includes(EPages.assets),
    },
    {
      href: `${BASE_PATH}${EPages.contracts}`,
      title: 'Contracts',
      logo: 'documentScanner',
      disabled: !organization,
      selected: asPath.includes(EPages.contracts),
    },
  ]

  const createOrganizationMenuOption = (
    <ListItemButton onClick={toggleOrganizationDialog}>
      <ListItemIcon>
        <ApartmentIcon fontSize="inherit" />
      </ListItemIcon>
      <ListItemText primary="New Organization" />
    </ListItemButton>
  )

  return (
    <>
      <StyledList>
        <Box>
          {TOP_NAVIGATION_OPTIONS.map(option => (
            <NavigationOption
              key={option.href}
              title={option.title}
              href={option.href}
              disabled={option.disabled}
              selected={option.selected || asPath === option.href}
              logo={option.logo}
            />
          ))}
        </Box>
        <Box>
          <Divider
            sx={{
              borderColor: (theme: Theme) => theme.colors.primary.white,
              margin: '29px 20px',
              opacity: 0.1,
            }}
          />

          {BOTTOM_NAVIGATION_OPTIONS.map(option => (
            <NavigationOption
              key={option.href}
              title={option.title}
              href={option.href}
              disabled={option.disabled}
              selected={option.selected || asPath === option.href}
              logo={option.logo}
            />
          ))}
          {isMojitoUser && createOrganizationMenuOption}
          <Box
            alignContent="center"
            display="flex"
            justifyContent="center"
            py={2.5}
            bgcolor={EColor.Black}
            mt="5px"
          >
            <Image src="/images/logo.svg" width={40} height={40} />
          </Box>
        </Box>
      </StyledList>

      <CreateOrganizationDialog
        isOpen={isOrganizationDialogOpen}
        onClose={toggleOrganizationDialog}
      />
    </>
  )
}

const CustomIcon = logo => {
  switch (logo) {
    case 'nft':
      return <NFTIcon fontSize="inherit" />
    case 'transfers':
      return <TransfersIcon fontSize="inherit" />
    case 'documentScanner':
      return <DocumentScannerIcon fontSize="inherit" />
    case 'filter':
      return <FilterIcon fontSize="inherit" />
    case 'customer':
      return <CustomersIcon fontSize="inherit" />
    case 'setting':
      return <SettingsIcon fontSize="inherit" />
    case 'accountBalanceWallet':
      return <AccountBalanceWalletOutlinedIcon fontSize="inherit" />
    case 'listings':
      return <ListAltIcon fontSize="inherit" />
    case 'Category':
      return <CategoryIcon fontSize="inherit" />
    default:
      return <StoreIcon fontSize="inherit" />
  }
}

const StyledList = styled(List)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: 0,
  '& .MuiListItemButton-root:hover': {
    backgroundColor: `${EColor.LightNavy} !important`,
    color: `${EColor.White} !important`,
    '& .MuiListItemIcon-root': {
      color: `${EColor.White} !important`,
    },
  },
})

export default Navigation
