import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const CopyIcon = memo(
  createSvgIcon(
    <path
      d="M12.5 18.5H2.5V5.5C2.5 4.95 2.05 4.5 1.5 4.5C0.95 4.5 0.5 4.95 0.5 5.5V18.5C0.5 19.6 1.4 20.5 2.5 20.5H12.5C13.05 20.5 13.5 20.05 13.5 19.5C13.5 18.95 13.05 18.5 12.5 18.5ZM17.5 14.5V2.5C17.5 1.4 16.6 0.5 15.5 0.5H6.5C5.4 0.5 4.5 1.4 4.5 2.5V14.5C4.5 15.6 5.4 16.5 6.5 16.5H15.5C16.6 16.5 17.5 15.6 17.5 14.5ZM15.5 14.5H6.5V2.5H15.5V14.5Z"
      fill="black"
    />,
    'CopyIcon'
  )
)
