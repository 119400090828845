import { memo } from 'react'
import { default as NextHead } from 'next/head'

interface PageHeadProps {
  title: string
}

export const PageHead = ({ title }: PageHeadProps) => {
  const _title = `Mojito - ${title}`

  return (
    <NextHead>
      <title>{_title}</title>
      <meta property="og:title" content={_title} key="title" />
    </NextHead>
  )
}

export default memo(PageHead)
