export enum EPages {
  dashboard = '/dashboard',
  collections = '/collections',
  assets = '/assets',
  minting = '/minting',
  customers = '/customers',
  index = '/',
  login = '/login',
  marketplaces = '/marketplaces',
  nfts = '/nfts',
  settings = '/settings',
  wallets = '/wallets',
  contracts = '/contracts',
  email_verify = '/email-verify',
  newuser = '/newuser',
  notFound = '/404',
  transfers = '/transfers',
  campaigns = '/campaigns',
  listings = '/listing-registry',
  registry = '/registry-details',
  registry_edit = '/registry-edit',
  artists = '/artists',
  category = '/category',
}
