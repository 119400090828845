import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const LinkIcon = memo(
  createSvgIcon(
    <path d="M8.75741 16.1321C7.40242 17.4871 5.22559 17.4871 3.87056 16.1321C2.51554 14.7772 2.51557 12.6003 3.87056 11.2453L6.46948 8.66856C7.33578 7.80225 8.75741 7.80225 9.62371 8.66856C10.179 9.22388 11.0898 9.22388 11.6451 8.66856C12.2005 8.11323 12.2005 7.20246 11.6451 6.64714C9.66818 4.67019 6.42509 4.67019 4.44814 6.64714L1.84923 9.22388C-0.616409 11.6895 -0.616409 15.6878 1.84923 18.1535C3.07098 19.3752 4.69248 19.9972 6.31402 19.9972C7.93553 19.9972 9.53486 19.3752 10.7788 18.1535C11.3341 17.5982 11.3341 16.6874 10.7788 16.1321C10.2237 15.5767 9.31289 15.5767 8.75757 16.1321L8.75741 16.1321ZM18.1535 1.84923C15.6878 -0.616409 11.6895 -0.616409 9.22388 1.84923C8.66856 2.40455 8.66856 3.31532 9.22388 3.87064C9.7792 4.42597 10.69 4.42597 11.2453 3.87064C12.6003 2.51565 14.7771 2.51565 16.1321 3.87064C17.4872 5.22563 17.4871 7.40246 16.1321 8.75749L13.5332 11.3342C12.6669 12.2227 11.2453 12.2227 10.379 11.3342C9.82367 10.7789 8.9129 10.7789 8.35758 11.3342C7.80225 11.8895 7.80225 12.8003 8.35758 13.3556C9.35712 14.3552 10.6455 14.8439 11.956 14.8439C13.2666 14.8439 14.5549 14.3552 15.5545 13.3556L18.1312 10.7789C20.6192 8.31327 20.6192 4.31494 18.1535 1.84931L18.1535 1.84923Z" />,
    'LinkIcon'
  )
)
