import * as yup from 'yup'

import { SendUserInvitationMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

export const sendUserInvitationMutationValidation = yup.object<
  Shape<SendUserInvitationMutationVariables>
>({
  email: yup.string().email('Invalid email format').required(),
  orgId: yup.string().required('Org ID is required.'),
})
