import { FunctionComponent } from 'react'
import Image from 'next/image'

import Box from '@mui/material/Box'
import { styled, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'

interface MessageContainerProps {
  title: JSX.Element | string
  titleSx?: SxProps<Theme>
}

export const MessageContainer: FunctionComponent<MessageContainerProps> = ({
  title,
  titleSx,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <StyledLogo>
        <Image
          unoptimized={true}
          src="/images/logo.svg"
          width={100}
          height={100}
        />
      </StyledLogo>
      <StyledTitle sx={titleSx} variant="h1">
        {title}
      </StyledTitle>
      {children}
    </Box>
  )
}

const StyledLogo = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  backgroundColor: theme.colors.other.yellow,
  borderRadius: '50%',
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.fonts.secondary,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '31px',
  marginTop: 30,
  marginBottom: 30,
}))
