import { FunctionComponent, useState } from 'react'
import { Controller } from 'react-hook-form'

import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
} from '@mui/material'

import { useFormWithSchema } from '@hooks/useFormWithSchema'
import {
  CreateOrgByUserMutationVariables,
  MeDocument,
  useCreateOrgByUserMutation,
} from '@services/graphql/generated'
import { Dialog } from '@shared'
import { createOrgByUserMutationValidation } from '@validations'

const NAME = 'name'
const HANDLE = 'handle'

interface CreateOrganizationDialogProps {
  isOpen: boolean
  onClose: () => void
}
const CreateOrganizationDialog: FunctionComponent<
  CreateOrganizationDialogProps
> = ({ isOpen, onClose }) => {
  const [createdOrg, setCreatedOrg] = useState<boolean>(false)

  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isDirty },
  } = useFormWithSchema<CreateOrgByUserMutationVariables>(
    createOrgByUserMutationValidation,
    {
      defaultValues: {
        [NAME]: '',
        [HANDLE]: '',
      },
    }
  )

  const [createOrg, { loading, error }] = useCreateOrgByUserMutation({
    errorPolicy: 'all',
  })

  const onSubmit = async ({ name, handle }) => {
    try {
      const data = await createOrg({
        variables: {
          name,
          handle,
        },
        refetchQueries: [
          {
            query: MeDocument,
          },
        ],
      })
      if (!data?.errors) {
        reset()
        setCreatedOrg(true)
      }
    } catch (e) {}
  }
  const onCloseHandler = () => {
    reset()
    onClose()
    setCreatedOrg(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      title="Create organization"
      action={handleSubmit(onSubmit)}
      actionLabel="Create"
      disabledAction={!watch(NAME as never) || !watch(HANDLE as never)}
      loading={loading}
    >
      <FormControl variant="standard" fullWidth>
        <InputLabel shrink htmlFor={NAME}>
          Name
        </InputLabel>

        <Controller
          name={NAME}
          control={control}
          defaultValue=""
          render={({ field }) => <InputBase {...field} fullWidth />}
        />
        {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
      </FormControl>
      <FormControl variant="standard" fullWidth sx={{ mt: 4 }}>
        <InputLabel shrink htmlFor={HANDLE}>
          Handle
        </InputLabel>
        <Controller
          name={HANDLE}
          control={control}
          defaultValue=""
          render={({ field }) => <InputBase {...field} fullWidth />}
        />

        {errors.handle && (
          <FormHelperText>{errors.handle.message}</FormHelperText>
        )}
      </FormControl>
      {<FormHelperText>{error?.message}</FormHelperText>}
      {createdOrg && !isDirty && (
        <FormHelperText>Organization successfully created</FormHelperText>
      )}
    </Dialog>
  )
}

export default CreateOrganizationDialog
