export const truncateStringInTheMiddle = (
  str = '',
  strLength = 41,
  strPositionStart = 6,
  strPositionEnd = 4
): string => {
  if (str.length > strLength) {
    return `${str.substr(0, strPositionStart)}...${str.substr(
      str?.length - strPositionEnd,
      str.length
    )}`
  }
  return str
}
