import * as yup from 'yup'

import {
  DeployContractInput,
  NftDeployContractMutationVariables,
} from '@services/graphql/generated'
import { Shape } from '@types'

const WALLET_ID = 'walletId'
const NFT_NAME = 'nftName'
const NFT_SYMBOL = 'nftSymbol'
const CONTRACT_TYPE = 'contractType'

export const nftDeployContractMutationValidation = yup.object<
  Shape<NftDeployContractMutationVariables>
>({
  input: yup.object<Shape<DeployContractInput>>({
    nftContractId: yup.string().notRequired(),
    walletId: yup
      .string()
      .required(`${WALLET_ID} is required.`)
      .min(3, 'Min length is 3.'),
    nftName: yup
      .string()
      .required(`${NFT_NAME} is required.`)
      .min(3, 'Min length is 3.'),
    nftSymbol: yup
      .string()
      .required(`${NFT_SYMBOL} is required.`)
      .min(3, 'Min length is 3.'),
    contractType: yup.string().required(`${CONTRACT_TYPE} is required.`),
    organizationId: yup.string().required('Organization ID is required.'),
  }),
})
