import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const LaunchIcon = memo(
  createSvgIcon(
    <>
      <path d="M20.4 22.8C21.7255 22.8 22.8 21.7255 22.8 20.4V3.60001C22.8 2.27453 21.7255 1.20001 20.4 1.20001H3.60001C2.27453 1.20001 1.20001 2.27453 1.20001 3.60001V10.8H3.60001V4.80001C3.60001 4.13727 4.13727 3.60001 4.80001 3.60001H19.2C19.8628 3.60001 20.4 4.13727 20.4 4.80001V19.2C20.4 19.8628 19.8628 20.4 19.2 20.4H13.2V22.8H20.4Z" />
      <path d="M6.46378 8.44041L15.2219 8.40001L15.2638 16.9257L11.7054 13.4946L4.12768 20.8013L2.40001 19.1354L9.97772 11.8287L6.46378 8.44041Z" />
    </>,
    'LaunchIcon'
  )
)
