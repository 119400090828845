import * as yup from 'yup'

import { UploadAssetMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

//NOT used yet
export const uploadAssetMutationValidation = yup.object<
  Shape<UploadAssetMutationVariables>
>({
  name: yup.string().required(`name is required.`).min(3, 'Min length is 3.'),
  file: yup.mixed().required('File is required'),
  orgId: yup.string().required('Org ID is required'),
})
