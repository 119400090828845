export const imageTypes = ['jpg', 'jpeg', 'png', 'gif']
export const videoTypes = ['mp4', 'mov']
export enum MediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
  NONE = 'NONE',
}

export function getMediaType(url?: string): MediaType {
  if (!url) {
    return MediaType.NONE
  }
  const parts = url.split('.')
  let ext = parts[parts.length - 1]
  if (ext.indexOf('?') > -1) {
    ext = ext.substring(0, ext.indexOf('?'))
  }

  if (imageTypes.indexOf(ext) > -1) {
    return MediaType.IMAGE
  } else if (videoTypes.indexOf(ext) > -1) {
    return MediaType.VIDEO
  }
  return MediaType.OTHER
}

export const isValidJSONString = obj => {
  try {
    JSON.parse(obj)
    return true
  } catch (e) {
    return false
  }
}
