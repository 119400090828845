import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import { StepConnector, stepConnectorClasses, styled } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepperMUI from '@mui/material/Stepper'
import Box from '@mui/system/Box'

import { EColor } from '@types'
import { animations } from '@utils'

const QontoConnector = styled(StepConnector)({
  width: '100%',
  maxWidth: 80,
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: EColor.Black,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: EColor.Black,
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: `1px dashed ${EColor.Gray500}`,
    },
  },
})

const QontoStepIcon = ({ active, completed }) =>
  completed ? (
    <CheckCircleIcon sx={{ color: EColor.Black, width: 16, height: 16 }} />
  ) : active ? (
    <Box
      position="relative"
      display="flex"
      sx={{
        '& .animation': { animation: `${animations.pulsing}  2s infinite` },
      }}
    >
      <CircleIcon
        sx={{
          stroke: EColor.Black,
          strokeWidth: 2,
          fill: EColor.White,
          width: 16,
          height: 16,
        }}
      />
      <CircleIcon
        sx={{
          position: 'absolute',
          fill: EColor.Black,
          width: 10,
          height: 10,
          left: 3,
          top: 3,
        }}
        className="animation"
      />
    </Box>
  ) : (
    <CircleIcon
      sx={{
        stroke: EColor.Gray500,
        strokeWidth: 2,
        fill: EColor.White,
        width: 16,
        height: 16,
      }}
    />
  )

export const Stepper = ({ step, steps }: { step: number; steps: string[] }) => {
  return (
    <StepperMUI
      activeStep={step}
      connector={<QontoConnector />}
      sx={{ width: '100%', justifyContent: 'center' }}
    >
      {steps?.map(label => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </StepperMUI>
  )
}
