import Image from 'next/image'

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import Loader from '@assets/mojito-loader.gif'

interface LoadingProps {
  fullscreen?: boolean
  small?: boolean
}

export const Loading = ({
  fullscreen = false,
  small = false,
}: LoadingProps) => (
  <BoxCentered fullscreen={fullscreen} pt={small ? 2 : 0}>
    <Image
      src={Loader}
      width={small ? 50 : 130}
      height={small ? 50 : 130}
      unoptimized
    />
  </BoxCentered>
)

const BoxCentered = styled(Box, {
  shouldForwardProp: prop => prop !== 'fullscreen',
})<{ fullscreen?: boolean }>(({ fullscreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: 'auto',
  position: fullscreen ? 'absolute' : 'relative',
  transform: fullscreen ? 'translate(-50%, -50%)' : 'none',
  top: fullscreen ? '50%' : 'auto',
  left: fullscreen ? '50%' : 'auto',
}))
