import React, { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

import MarketplaceMenu from '@components/navigation/MarketplaceMenu'
import Navigation from '@components/navigation/Navigation'
import OrganizationMenu from '@components/navigation/OrganizationMenu'
import { EColor } from '@types'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <StyledWrapper className="Layout">
      <CssBaseline />
      <StyledNav component="nav">
        <StyledToolbar>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <StyledMenuBtn
              color="inherit"
              aria-label="Open navigation"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </StyledMenuBtn>
          </Box>
          <OrganizationMenu />
        </StyledToolbar>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <SyledDrawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <StyledToolbar>
              <StyledCloseMenuBtn onClick={handleDrawerToggle}>
                <CloseIcon />
              </StyledCloseMenuBtn>
            </StyledToolbar>
            <MarketplaceMenu />
            <Navigation />
          </SyledDrawer>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <SyledDrawer variant="permanent">
            <MarketplaceMenu />
            <Navigation />
          </SyledDrawer>
        </Box>
      </StyledNav>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow="hidden"
      >
        {children}
      </Box>
    </StyledWrapper>
  )
}

const StyledNav = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: theme.navigation.width,
  },
}))

const SyledDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.navigation.width,
  '& .MuiDrawer-paper': {
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      width: theme.navigation.width,
      flexShrink: 0,
      paddingTop: theme.navigation.toolbarHeight,
    },
    flexDirection: 'column',
    background: theme.navigation.background,
  },
  '& .MuiListItemButton-root': {
    borderRadius: theme.navigation.borderRadius,
    margin: theme.navigation.margin,
    padding: 8,
    '&:hover': {
      backgroundColor: theme.colors.secondary.pink,
      color: theme.colors.primary.dark,
      '& .MuiListItemIcon-root': {
        color: theme.colors.primary.dark,
      },
    },
  },
  '& .MuiListItemButton-root, .MuiListItemIcon-root': {
    color: theme.colors.primary.white,
  },
  '& .MuiListItemButton-root.Mui-selected': {
    backgroundColor: theme.colors.secondary.pink,
    color: theme.colors.primary.dark,
    '& .MuiListItemIcon-root': {
      color: theme.colors.primary.dark,
    },
    '&:hover': {
      backgroundColor: theme.colors.secondary.pink,
      color: theme.colors.primary.dark,
      '& .MuiListItemIcon-root': {
        color: theme.colors.primary.dark,
      },
    },
    '&:after': {
      content: '""',
      display: 'inline-block',
      width: '4px',
      height: '24px',
      background: EColor.Gray900,
      borderRadius: theme.navigation.borderRadius,
    },
  },
  '& .MuiTypography-body1': {
    fontSize: theme.navigation.fontSize,
    lineHeight: theme.navigation.lineHeight,
    marginLeft: 10,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  background: EColor.Dark300,
  height: theme.navigation.toolbarHeight,
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up('sm')]: {
    position: 'fixed',
    padding: 0,
    left: 0,
    top: 0,
    width: theme.navigation.width,
  },
}))

const StyledCloseMenuBtn = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  paddingLeft: 0,
}))

const StyledMenuBtn = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.common.white,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
