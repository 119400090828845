import * as yup from 'yup'

import { UpdateMarketplaceBuyNowLotMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

export const updateMarketplaceBuyNowLotMutationValidation = yup.object<
  Shape<UpdateMarketplaceBuyNowLotMutationVariables>
>({
  input: yup.object<Shape<any>>({
    sortNumber: yup.number().notRequired(),
    unitPrice: yup.string().notRequired(),
    totalUnits: yup.string().notRequired(),
    startDate: yup.date().notRequired(),
    endDate: yup.date().notRequired(),
    delivery: yup.object().notRequired(),
    artistId: yup.string().notRequired(),
  }),
  marketplaceBuyNowLotID: yup
    .string()
    .required('MarketplaceBuyNowLotID is required'),
})
