import { forwardRef } from 'react'

import DateRangeIcon from '@mui/icons-material/DateRange'
import DateAdapter from '@mui/lab/AdapterDayjs'
import DateTimePicker, { DateTimePickerProps } from '@mui/lab/DateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TextField from '@mui/material/TextField'

import { Scalars } from '@services/graphql/generated'
import { COLLECTION_DATE_FORMAT, dayjs } from '@utils/dayjsUtils'

//FIXME `Scalars['Time']` type is `any` which is not type-safe
type DatePickerProps = Omit<DateTimePickerProps<Scalars['Time']>, 'renderInput'>

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  ({ maxDateTime, minDateTime, onChange, ...props }, ref) => (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DateTimePicker
        ref={ref}
        {...props}
        {...(maxDateTime && { maxDateTime: dayjs(maxDateTime) })}
        {...(minDateTime && { minDateTime: dayjs(minDateTime) })}
        ampm
        onChange={(e: any) => onChange(e?.$d)}
        components={{
          OpenPickerIcon: DateRangeIcon,
        }}
        renderInput={params => (
          <TextField
            fullWidth
            variant="standard"
            {...params}
            onChange={e => {
              let rawDate = e.target.value
              if (!rawDate) {
                onChange(null)
              } else {
                rawDate = rawDate.trim()
                const date = new Date(rawDate)
                const result = dayjs(date).format(COLLECTION_DATE_FORMAT)
                onChange(rawDate === result ? date : null)
              }
            }}
          />
        )}
        inputFormat={COLLECTION_DATE_FORMAT}
      />
    </LocalizationProvider>
  )
)
