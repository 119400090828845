import * as yup from 'yup'

import { CreateOrgMultisigMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

export const createOrgMultisigMutationValidation = yup.object<
  Shape<CreateOrgMultisigMutationVariables>
>({
  name: yup.string().required(`name is required.`).min(3, 'Min length is 3.'),
  chainId: yup.number().required(`chainId is required.`),
  orgId: yup.string().required(`orgId is required.`),
})
