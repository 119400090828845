import { useEffect } from 'react'
import { NextPage } from 'next'
import App, { AppProps } from 'next/app'
import { Auth0Provider } from '@auth0/auth0-react'
import TagManager from 'react-gtm-module'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { AuthorizedApolloProvider } from '@components/auth/AuthorizedApolloProvider'
import { config } from '@constants/general.constants'
import { datadogRum } from '@datadog/browser-rum'
import { KeyLogger, Layout, ProtectedPageContainer } from '@shared'
import theme from '@themes/default'

type NextPageWithLayout = NextPage & {
  withoutLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const tagManagerArgs = {
  gtmId: config.GOOGLE_TAG_MANAGER_ID,
}

if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === 'development' &&
  location.hostname === 'localhost'
) {
  // eslint-disable-next-line no-console
  console.log('Skipping Datadog...')
} else {
  datadogRum.init({
    applicationId: config.DATADOG_APPLICATION_ID || '',
    clientToken: config.DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    service: 'mojito-mint',
    env: process.env.NODE_ENV,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogRum.startSessionReplayRecording()
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN || ''}
      clientId={config.AUTH0_CLIENTID || ''}
      redirectUri={config.AUTH_REDIRECT_URI}
    >
      <AuthorizedApolloProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ProtectedPageContainer>
            {Component.withoutLayout ? (
              <Component {...pageProps} />
            ) : (
              <Layout>{<Component {...pageProps} />}</Layout>
            )}
          </ProtectedPageContainer>
        </ThemeProvider>
        <KeyLogger />
      </AuthorizedApolloProvider>
    </Auth0Provider>
  )
}

MyApp.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)

  return { ...appProps }
}

export default MyApp
