import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const AccordionSummary = ({
  title,
  expanded,
}: {
  title: string
  expanded: boolean
}) => (
  <StyledAccordionSummary expanded={expanded}>
    <StyledAccordionTitle>{title}</StyledAccordionTitle>
  </StyledAccordionSummary>
)

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  padding: '0 20px',
  '&:not(:last-child)': {
    border: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    paddingBottom: 20,
  },
  '&:first-of-type': {
    paddingTop: 20,
  },
})

const StyledAccordionSummary = styled(
  ({ expanded, ...rest }: { expanded: boolean }) => (
    <MuiAccordionSummary
      expandIcon={
        expanded ? (
          <RemoveIcon sx={{ fontSize: 13 }} />
        ) : (
          <AddIcon sx={{ fontSize: 13 }} />
        )
      }
      {...rest}
    />
  )
)({
  minHeight: 57,
})

const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '13.2154px',
  lineHeight: '17px',
  fontWeight: 600,
  textTransform: 'uppercase',
  fontFamily: theme.fonts.secondary,
}))

export { Accordion, AccordionSummary }
