import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const SmallCrossIcon = memo(
  createSvgIcon(
    <>
      <path d="M17.7677 19.9999C17.2286 19.9997 16.7116 19.7891 16.3299 19.4142L8.20995 11.4401C8.02118 11.2546 7.8714 11.0343 7.76921 10.7917C7.66702 10.5491 7.61442 10.289 7.61442 10.0263C7.61442 9.76366 7.66702 9.50358 7.76921 9.26098C7.8714 9.01837 8.02118 8.798 8.20995 8.61249L16.3347 0.585625C16.7154 0.210655 17.2317 0 17.7701 0C18.3085 0 18.8248 0.210655 19.2055 0.585625C19.5861 0.960594 19.8 1.46916 19.8 1.99945C19.8 2.52973 19.5861 3.0383 19.2055 3.41327L12.5185 9.99991L19.2055 16.5866C19.4898 16.8682 19.6826 17.2268 19.7594 17.6168C19.8362 18.0067 19.7936 18.4104 19.6368 18.7763C19.48 19.1423 19.2163 19.4541 18.8791 19.6719C18.5419 19.8898 18.1465 20.004 17.7433 19.9999H17.7677Z" />
      <path d="M2.03235 0.000107003C2.5714 0.000285735 3.08842 0.210894 3.47014 0.5858L11.59 8.55986C11.7788 8.74537 11.9286 8.96574 12.0308 9.20834C12.133 9.45095 12.1856 9.71103 12.1856 9.97368C12.1856 10.2363 12.133 10.4964 12.0308 10.739C11.9286 10.9816 11.7788 11.202 11.59 11.3875L3.46527 19.4144C3.08459 19.7893 2.56827 20 2.02991 20C1.49154 20 0.975228 19.7893 0.594547 19.4144C0.213865 19.0394 -4.70654e-08 18.5308 0 18.0006C4.70654e-08 17.4703 0.213865 16.9617 0.594547 16.5867L7.28153 10.0001L0.594548 3.41345C0.310211 3.13178 0.11737 2.77315 0.0405626 2.38321C-0.0362451 1.99327 0.00645087 1.58964 0.163221 1.22367C0.31999 0.857699 0.583746 0.545941 0.920927 0.328057C1.25811 0.110173 1.65347 -0.00398659 2.05672 0.000107005L2.03235 0.000107003Z" />
    </>,
    'SmallCrossIcon'
  )
)
