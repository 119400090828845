import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.colors.primary.dark,
  fontWeight: 600,
  fontSize: 13.2154,
  lineHeight: '17px',
  textTransform: 'uppercase',
  fontFamily: theme.fonts.secondary,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.colors.primary.green,
  },
}))
