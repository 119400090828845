import React, { memo, MouseEvent, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

import { localStorageKeys } from '@constants/general.constants'
import {
  Marketplace,
  OrganizationQuery,
  useOrganizationQuery,
} from '@services/graphql/generated'
import theme from '@themes/default'
import { EColor, SavedRoute } from '@types'

interface MarketplaceSelectProps {
  data?: OrganizationQuery
  loading: boolean
  marketplace?: string | string[]
  organization?: string | string[]
}

const MarketplaceSelect = memo(function MarketplaceSelectFunc({
  organization,
  marketplace,
  data,
  loading,
}: MarketplaceSelectProps) {
  const marketplaces = data?.organization?.marketplaces
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedMarketplace, setSelectedMarketplace] = useState<string | null>(
    marketplace as string
  )
  const isOpen = Boolean(anchorEl)

  useEffect(() => {
    setSelectedMarketplace(marketplace as string)
  }, [marketplace])

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)

  const handleMenuItemClick = (
    marketplace: Pick<Marketplace, 'id' | 'name' | 'theme'>,
    e: MouseEvent<HTMLLIElement>
  ) => {
    if (!(e.ctrlKey || e.metaKey)) {
      setSelectedMarketplace(marketplace.id)
    }
    setAnchorEl(null)
    const savedRoute = localStorage.getItem(localStorageKeys.SavedRoute) || '{}'
    const route: Partial<SavedRoute> = JSON.parse(savedRoute)
    route.marketplaceID = marketplace.id as string
    localStorage.setItem(localStorageKeys.SavedRoute, JSON.stringify(route))
  }

  const handleClose = () => setAnchorEl(null)

  const selectedMarketplaceName = useMemo(
    () =>
      marketplaces?.filter(mk => mk.id === selectedMarketplace)?.[0]?.name ??
      'Select marketplace',
    [marketplaces, selectedMarketplace]
  )

  return (
    <Box p={2}>
      <StyledList aria-label="Select organization" open={isOpen}>
        <ListItem
          button
          id="organization-menu"
          aria-haspopup="listbox"
          aria-controls="organization-menu"
          aria-label="Selected Organization"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClickListItem}
          disabled={loading}
        >
          <StyledListItemText
            primary="Choose marketplace"
            secondary={selectedMarketplaceName}
          />
          <StyledIcon
            fontSize="small"
            sx={{
              mr: -1,
              mb: -2,
              transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
              transition: '0.2s',
            }}
          />
        </ListItem>
      </StyledList>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        variant="menu"
      >
        {marketplaces?.map(marketplace => (
          <MenuItem
            key={marketplace.id}
            sx={{
              '& a': {
                ...theme.content.ellipsis,
                fontSize: 16,
              },
            }}
            selected={marketplace.id === selectedMarketplace}
            onClick={e => handleMenuItemClick(marketplace, e)}
          >
            <Link href={`/${organization}/${marketplace.id}/collections`}>
              <a>{marketplace.name}</a>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
})

const MarketplaceMenu = () => {
  const router = useRouter()
  const { organization, marketplace } = router.query
  const { data, loading } = useOrganizationQuery({
    variables: {
      handle: organization as string,
    },
  })

  return (
    <MarketplaceSelect
      organization={organization}
      marketplace={marketplace}
      data={data}
      loading={loading}
    />
  )
}

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    opacity: '0.4',
    color: theme.palette.common.white,
    fontFamily: theme => theme.fonts.secondary,
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    marginTop: 2,
    ...theme.content.ellipsis,
  },
}))

const StyledIcon = styled(KeyboardArrowDown)(({ theme }) => ({
  color: theme.palette.common.white,
}))

const StyledList = styled(List)(({ open }: { open: boolean }) => ({
  '& .MuiListItem-button': {
    background: EColor.Dark900,
    borderRadius: open ? '8px 8px 0 0' : 8,
    '&:hover': {
      background: EColor.Dark900,
    },
    '& .MuiTypography-body1': {
      marginLeft: 0,
    },
  },
}))

export default memo(MarketplaceMenu)
