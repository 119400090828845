import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const CustomersIcon = memo(
  createSvgIcon(
    <path
      d="M16.0238 12.7051C16.9363 11.8786 17.5129 10.6858 17.5129 9.36291C17.5129 6.87884 15.4822 4.85 12.9983 4.85C10.5143 4.85 8.48534 6.87892 8.48534 9.36291C8.48534 10.6856 9.0614 11.8786 9.97353 12.7051C6.9746 13.9089 4.85 16.8469 4.85 20.2711L4.85 20.2714C4.85046 20.5038 4.9431 20.7265 5.10759 20.8907C5.27208 21.0548 5.49499 21.147 5.72739 21.147C5.95979 21.147 6.18269 21.0548 6.34719 20.8907C6.51168 20.7265 6.60432 20.5038 6.60478 20.2714V20.2711C6.60478 16.7306 9.45789 13.8758 12.9983 13.8758C16.5386 13.8758 19.3933 16.7305 19.3935 20.2708L16.0238 12.7051ZM16.0238 12.7051C19.0229 13.9089 21.1482 16.8467 21.1483 20.2708L16.0238 12.7051ZM19.3935 20.2709C19.3933 20.3862 19.4158 20.5005 19.4598 20.6071C19.5037 20.7138 19.5683 20.8107 19.6498 20.8924C19.7313 20.974 19.8281 21.0388 19.9347 21.083C20.0413 21.1272 20.1555 21.15 20.2709 21.15C20.3863 21.15 20.5005 21.1272 20.6071 21.083C20.7137 21.0388 20.8105 20.974 20.892 20.8924C20.9735 20.8107 21.038 20.7138 21.082 20.6071C21.126 20.5005 21.1485 20.3863 21.1483 20.2711L19.3935 20.2709ZM12.9983 6.60478C14.5307 6.60478 15.7582 7.83075 15.7582 9.36291C15.7582 10.8952 14.5306 12.1225 12.9983 12.1225C11.4661 12.1225 10.2401 10.8953 10.2401 9.36291C10.2401 7.83063 11.466 6.60478 12.9983 6.60478Z"
      strokeWidth="0.3"
    />,
    'CustomersIcon'
  )
)
