import React, { FunctionComponent } from 'react'
import { useRouter } from 'next/router'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'

import { EColor } from '@types'
import { useList } from './ListContext'

interface ListGridProps {
  children: React.ReactNode
  subHeader?: string
  withPagination: boolean
}

export const ListGrid: FunctionComponent<ListGridProps> = ({
  children,
  subHeader,
  withPagination,
}) => {
  const { direction, handleNextPage, handlePrevPage } = useList()
  const { query } = useRouter()

  return (
    <>
      <Box
        display="flex"
        justifyContent={subHeader ? 'space-between' : 'flex-end'}
      >
        {subHeader && <StyledHeader>{subHeader}</StyledHeader>}
        {withPagination && (
          <Box alignSelf="flex-end" px={3} pt={2}>
            <IconButton
              aria-label="Previous Page"
              onClick={handlePrevPage}
              disabled={!query?.page}
            >
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="Next Page" onClick={handleNextPage}>
              <ChevronRightIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      {!!children && (
        <Grid
          container
          direction={direction}
          justifyContent="flex-start"
          alignItems="space-between"
          flexGrow={1}
          mt={0}
          columnSpacing={3}
          rowSpacing={3}
        >
          {children}
        </Grid>
      )}
    </>
  )
}

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontFamily: theme.fonts.secondary,
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16px',
  color: EColor.Gray900,
  opacity: 0.5,
}))
