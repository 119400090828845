import { PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'js-cookie'

import { config, localStorageKeys } from '@constants/general.constants'
import { MeQuery, useMeLazyQuery } from '@services/graphql/generated'
import { EPages, SavedRoute } from '@types'
import { Loading } from './Loading'

const generateIndexRedirectUrl = (me: MeQuery['me']): string => {
  const organizations = me?.userOrgs

  if (!organizations?.length) {
    return EPages.newuser
  }

  if (organizations?.length > 0) {
    let handle = organizations[0].organization.handle
    let marketplace = ''
    const savedRoute = localStorage.getItem(localStorageKeys.SavedRoute) || '{}'
    const route: Partial<SavedRoute> = JSON.parse(savedRoute)
    if (route?.userId === me?.user.id) {
      handle = route.handle ?? ''
      marketplace = route.marketplaceID ?? ''
    } else {
      const route: Partial<SavedRoute> = {
        userId: me?.user.id,
        handle,
      }
      localStorage.setItem(localStorageKeys.SavedRoute, JSON.stringify(route))
    }
    return `${handle}${marketplace ? `/${marketplace}` : ''}`
  }

  return ''
}

export function ProtectedPageContainer({
  redirectUrl = EPages.login,
  revertDirection = false,
  // prefetchedQuery = null,
  children,
}: //TODO ekuznetski- add prefetchedQuery - query which should be called on page init
PropsWithChildren<{
  redirectUrl?: EPages
  revertDirection?: boolean
  // prefetchedQuery?: any;
}>) {
  const { isAuthenticated, isLoading: loadingAuth0 } = useAuth0()
  const [lazyMeQuery, { data, loading: loadingMeQuery }] = useMeLazyQuery()
  const [canAccessOrg, setCanAccessOrg] = useState<undefined | boolean>(
    undefined
  )

  const router = useRouter()
  const auth0Cookie = Cookies.get(
    `auth0.${config.AUTH0_CLIENTID}.is.authenticated`
  )

  const routeIsAuthenticated = isAuthenticated && auth0Cookie

  useEffect(() => {
    if (isAuthenticated) {
      lazyMeQuery()
    }
  }, [isAuthenticated, lazyMeQuery])

  // This is a temporary solution until the api validate the user access to the requested organization
  useEffect(() => {
    if (
      routeIsAuthenticated &&
      router.pathname !== EPages.notFound &&
      router.query.organization &&
      data
    ) {
      const canAccessOrg = data?.me?.userOrgs?.some(
        userOrg => userOrg.organization.handle === router.query.organization
      )
      setCanAccessOrg(canAccessOrg)
    }
  }, [routeIsAuthenticated, data, router])

  useEffect(() => {
    if (canAccessOrg === false && router.asPath !== EPages.notFound) {
      router.push(EPages.notFound)
    }
  }, [canAccessOrg, router])

  useEffect(() => {
    const redirect = async () => {
      try {
        if (data?.me && router.pathname === EPages.index) {
          const redirectUrl = generateIndexRedirectUrl(data?.me)

          await router.push(redirectUrl)
        }
      } catch (e) {}
    }
    redirect()
  }, [data?.me, router])

  useEffect(() => {
    if (
      !loadingAuth0 &&
      data &&
      router.pathname !== redirectUrl &&
      ((revertDirection && isAuthenticated) ||
        (!revertDirection && !isAuthenticated) ||
        !auth0Cookie)
    ) {
      router.push(redirectUrl)
    }
  }, [
    loadingAuth0,
    isAuthenticated,
    redirectUrl,
    revertDirection,
    router,
    auth0Cookie,
    lazyMeQuery,
    data,
  ])

  if (loadingAuth0 || loadingMeQuery) {
    return <Loading fullscreen={true} />
  }

  return <>{children}</>
}
