import React from 'react'

import Box from '@mui/material/Box'

import { useCountdown } from '@hooks/useCountDown'
import { DateTimeDisplay } from '@shared'

const ShowCounter = ({ hours, minutes }) => {
  return (
    <Box
      sx={{
        marginLeft: 1,
        fontFamily: 'Graphik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '10px',
        color: '#999999',
      }}
    >
      <DateTimeDisplay value={hours} type={'hrs'} />
      <span> | </span>
      <DateTimeDisplay value={minutes} type={'min'} />
    </Box>
  )
}

export const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days + hours + minutes + seconds <= 0) {
    return null
  } else {
    return <ShowCounter hours={hours} minutes={minutes} />
  }
}
