import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'

import { EPages } from '@types'

const useMojitoUser = () => {
  const [isMojitoUser, setIsMojitoUser] = useState<boolean>(false)
  const { isLoading, getIdTokenClaims, isAuthenticated } = useAuth0()
  const mountedRef = useRef(true)
  const router = useRouter()

  useEffect(() => {
    const getClaims = async () => {
      try {
        if (!isAuthenticated) {
          router.push(EPages.index)
          return
        }
        const idTokenClaims = await getIdTokenClaims()
        const domain = /(?<=@)[^.]+(?=\.)/.exec(idTokenClaims?.email || '')

        //TODO: implement a real role feature
        if (mountedRef.current && domain && domain[0] === 'mojito') {
          setIsMojitoUser(true)
        }
      } catch (e) {
        if (!mountedRef.current) return null
      }
    }

    if (!isLoading) {
      getClaims()
    }

    return () => {
      mountedRef.current = false
    }
  }, [isLoading, getIdTokenClaims, isAuthenticated, router])

  return {
    isMojitoUser,
  }
}

export default useMojitoUser
