import dayjs, { extend } from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

extend(relativeTime)
extend(utc)
extend(timezone)
extend(advanced)

const BUY_NOW_DATE_FORMAT = 'HH:mm DD MMMM z'
const BID_DATE_FORMAT = 'HH:mm DD MMMM z'
const COLLECTION_DATE_FORMAT = 'D MMM, YYYY, h:mm A z'
const ITEM_DATE_FORMAT = 'DD MMM, h:mm A z'
const TRANSFER_TOKEN_DATE_FORMAT = 'YYYY-MM-DD, HH:mm'

export {
  BID_DATE_FORMAT,
  BUY_NOW_DATE_FORMAT,
  COLLECTION_DATE_FORMAT,
  dayjs,
  ITEM_DATE_FORMAT,
  TRANSFER_TOKEN_DATE_FORMAT,
}
