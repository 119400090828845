import * as yup from 'yup'

import {
  CreateCollectionMutationVariables,
  MarketplaceCollectionCreateInput,
} from '@services/graphql/generated'
import { Shape } from '@types'

export const createCollectionMutationValidation = yup.object<
  Shape<CreateCollectionMutationVariables>
>({
  data: yup.object<Shape<MarketplaceCollectionCreateInput>>({
    name: yup.string().required(`name is required.`).min(3, 'Min length is 3.'),
    status: yup.string().notRequired(),
    description: yup.string(),
    startDate: yup.date().notRequired(),
    endDate: yup.date().notRequired(),
    artistIDs: yup.array().notRequired(),
    redirectUrl: yup.string().notRequired(),
  }),
  marketplaceID: yup.string().required(),
})
