import * as yup from 'yup'

import {
  UploadAssetMutationVariables,
  UploadAssetsMutationVariables,
} from '@services/graphql/generated'
import { Shape } from '@types'

export const uploadAssetsMutationValidation = yup.object<
  Shape<UploadAssetsMutationVariables & UploadAssetMutationVariables>
>({
  files: yup.mixed().required('File is required'),
  name: yup.string().notRequired(),
  file: yup.mixed().notRequired(),
  orgId: yup.string().required('Org ID is required'),
})
