import * as yup from 'yup'

import {
  DeliveryMethod,
  MarketplaceItemDeliveryErc721TransferInput,
  MarketplaceItemDeliveryErc1155TransferInput,
  MarketplaceItemDeliveryInput,
  MarketplaceItemDeliveryNoOpInput,
  UpdateMarketplaceClaimableSetInput,
  UpdateMarketplaceClaimableSetMutationVariables,
} from '@services/graphql/generated'
import { Shape } from '@types'

export const updateMarketplaceClaimableSetMutationVariablesValidation =
  yup.object<Shape<UpdateMarketplaceClaimableSetMutationVariables>>({
    marketplaceClaimableSetID: yup
      .string()
      .required('Marketplace Claimable Set ID is required'),
    input: yup.object<Shape<UpdateMarketplaceClaimableSetInput>>({
      totalUnits: yup.number().notRequired(),
      collectionItemName: yup.string().notRequired(),
      description: yup.string().notRequired(),
      totalAvailableUnits: yup
        .number()
        .notRequired()
        .max(
          yup.ref('totalUnits'),
          'Total available units should be smaller than total units'
        ),
      startDate: yup.date().notRequired(),
      endDate: yup.date().notRequired(),
      delivery: yup.object<Shape<MarketplaceItemDeliveryInput>>({
        deliveryMethod: yup.string().notRequired(),
        ERC721Provenance: yup.object().notRequired(),
        ERC1155OpenEdition: yup.object().notRequired(),
        ERC721TransferByRange: yup.object().notRequired(),
        ERC1155Provenance: yup.object().notRequired(),
        ERC1155Transfer: yup
          .object()
          .notRequired()
          .when('deliveryMethod', {
            is: (deliveryMethod: DeliveryMethod) => {
              if (deliveryMethod === DeliveryMethod.Erc1155Transfer) {
                return true
              }
              return false
            },
            then: yup.object<
              Shape<MarketplaceItemDeliveryErc1155TransferInput>
            >({
              amount: yup.string().notRequired().nullable().default(null),
              contractAddress: yup
                .string()
                .required('ContractAddress is required'),
              ownerWalletId: yup.string().required('OwnerWalletId is required'),
              onChainTokenId: yup
                .number()
                .required('OnChainTokenId is required'),
            }),
          }),

        ERC721Transfer: yup
          .object()
          .notRequired()
          .when('deliveryMethod', {
            is: (deliveryMethod: DeliveryMethod) => {
              if (deliveryMethod === DeliveryMethod.Erc721Transfer) {
                return true
              }
              return false
            },
            then: yup.object<Shape<MarketplaceItemDeliveryErc721TransferInput>>(
              {
                contractAddress: yup
                  .string()
                  .required('ContractAddress is required'),
                ownerWalletId: yup
                  .string()
                  .required('OwnerWalletId is required'),
                onChainTokenId: yup
                  .number()
                  .required('OnChainTokenId is required'),
                randomizeTokensInAddress: yup
                  .string()
                  .notRequired()
                  .nullable()
                  .default(null),
                RelayTxInfo: yup
                  .object()
                  .notRequired()
                  .nullable()
                  .default(null),
              }
            ),
          }),
        NoOp: yup
          .object()
          .notRequired()
          .when('deliveryMethod', {
            is: (deliveryMethod: DeliveryMethod) => {
              if (deliveryMethod === DeliveryMethod.NoOp) {
                return true
              }
              return false
            },
            then: yup.object<Shape<MarketplaceItemDeliveryNoOpInput>>({
              notes: yup.string().required('NoOp is required'),
              noOpcontractAddress: yup
                .string()
                .required('Contract Address is required'),
              noOpOnChainTokenId: yup
                .number()
                .required('OnChainTokenId is required'),
            }),
          }),
      }),
    }),
  })
