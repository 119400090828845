import { useEffect, useState } from 'react'

const useKeyPress = (targetKey: string): boolean => {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = ({ shiftKey, key }): void => {
    if (shiftKey && key === targetKey.toUpperCase()) {
      setKeyPressed(true)
    }
  }

  const upHandler = ({ shiftKey, key }): void => {
    if (shiftKey && key === targetKey.toUpperCase()) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return keyPressed
}

export default useKeyPress
