import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { createTheme } from '@mui/material'

import { MarketCollectionStatus } from '@services/graphql/generated'
import { EColor, EFonts, EShadow } from '@types'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    infoLabel: true
    info: true
    cardTitle: true
    body2: true
    subtitle3: true
  }
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    section: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedGreen: true
    containedPink: true
    containedDarkGrey: true
    containedLightGrey: true
    containedGrey: true
    containedWhite: true
    action: true
    mainToolbar: true
    secondaryToolbar: true
    link: true
    black: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    navigation: {
      background: string
      width: number
      fontSize: number
      lineHeight: string
      borderRadius: number
      margin: string
      toolbarHeight: number
    }
    aside: {
      width: number
    }
    content: {
      padding: string
      border: string
      dottedBackground: Record<string, unknown>
      ellipsis: Record<string, unknown>
    }
    colors: {
      primary: {
        dark: string
        green: string
        white: string
      }
      secondary: {
        greeny: string
        purple: string
        darkGrey: string
        pink: string
      }
      tertiary: {
        grey: string
        darkGrey: string
      }
      other: {
        error: string
        border: string
        yellow: string
        ethScanBlue: string
        mojitoGold: string
      }
      collection: {
        [MarketCollectionStatus.Active]: string
        [MarketCollectionStatus.Inactive]: string
        [MarketCollectionStatus.Archived]: string
      }
    }
    fonts: {
      primary: string
      secondary: string
    }
  }
  interface ThemeOptions {
    navigation?: {
      background?: string
      width?: number
      fontSize?: number
      lineHeight?: string
      borderRadius?: number
      margin?: string
      toolbarHeight?: number
    }
    aside: {
      width?: number
    }
    content?: {
      padding?: string
      border?: string
      dottedBackground?: Record<string, unknown>
      ellipsis: Record<string, unknown>
    }
    colors?: {
      primary?: {
        dark?: string
        green?: string
        white?: string
      }
      secondary?: {
        greeny?: string
        purple?: string
        darkGrey?: string
        pink?: string
      }
      tertiary?: {
        grey?: string
        darkGrey?: string
      }
      other: {
        error?: string
        border?: string
        yellow?: string
        ethScanBlue?: string
        mojitoGold?: string
      }
      collection: {
        [MarketCollectionStatus.Active]: string
        [MarketCollectionStatus.Inactive]: string
        [MarketCollectionStatus.Archived]: string
      }
    }
    fonts: {
      primary?: string
      secondary?: string
    }
  }
}

const theme = createTheme({
  typography: {
    fontFamily: [EFonts.Graphik, EFonts.IBMPlexSans, 'Arial'].join(','),
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: '0 !important',
        },
        a: {
          color: EColor.Violet,
          textDecoration: 'auto',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& tr': {
            '>:first-of-type': {
              borderRadius: '10px 0 0 10px',
            },
            '>th +  td:first-of-type': {
              borderRadius: 0,
            },
            '>:last-child': {
              borderRadius: '0 10px 10px 0',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          '&.MuiTableCell-head': {
            fontFamily: EFonts.IBMPlexSans,
            fontWeight: 600,
            fontSize: 12,
            letterSpacing: '0.01em',
            color: EColor.Gray500,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-head': {
            width: '100%',
            borderRadius: 0,
            padding: 0,
            borderBottom: `1px solid ${EColor.Gray300}`,
            '&:first-of-type': {
              backgroundColor: EColor.White,
            },
            '& .MuiTypography-root': {
              color: EColor.Gray500,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottomColor: 'transparent',
          borderTopColor: 'transparent',
          outlineColor: 'transparent',
          borderRadius: 10,
          '&:nth-of-type(even)': {
            backgroundColor: EColor.Neutral900,
          },
          '&.Mui-selected': {
            outline: `2px solid ${EColor.Green}`,
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'infoLabel' },
          style: {
            fontFamily: EFonts.Graphik,
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '15px',
            color: EColor.Black,
            opacity: 0.4,
            marginBottom: 2,
          },
        },
        {
          props: { variant: 'info' },
          style: {
            fontFamily: EFonts.Graphik,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '20px',
            color: EColor.Black,
          },
        },
        {
          props: { variant: 'cardTitle' },
          style: {
            fontFamily: EFonts.IBMPlexSans,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '18px',
            color: EColor.Black,
            marginBottom: 20,
          },
        },
        {
          props: { variant: 'subtitle3' },
          style: {
            fontFamily: EFonts.IBMPlexSans,
            fontWeight: 600,
            fontSize: 12,
            lineHeight: '18px',
            letterSpacing: '0.01em',
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: EFonts.IBMPlexSans,
            fontWeight: 600,
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: '-0.02em',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontFamily: EFonts.IBMPlexSans,
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px',
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: EColor.Gray800,
          '&:hover': {
            color: EColor.Green,
            backgroundColor: 'transparent',
          },
          '&:focus': {
            color: EColor.Green,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          width: '100%',
          overflow: 'visible',
          boxShadow: EShadow.Normal,
          border: '2px solid transparent',
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          marginBottom: 20,
          '& .MuiFormControl-root': {
            marginTop: 0,
          },
        },
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            marginTop: 20,
            '&:first-of-type': {
              marginTop: 0,
            },
            '& .MuiInput-root': {
              '&:before, &:after': {
                border: 0,
              },
              '&:hover': {
                '&:before': {
                  border: 0,
                },
              },
            },
          },
        },
      ],
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& p': {
            color: EColor.Gray500,
            fontSize: 10,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "input[type='number']::-webkit-inner-spin-button": {
            WebkitAppearance: 'none',
            margin: 0,
          },
          "input[type='number']::-webkit-outer-spin-button": {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& .MuiInputAdornment-root': {
            position: 'absolute',
            right: 12,
            bottom: 20,
          },
          '& .MuiInputBase-input': {
            fontFamily: EFonts.IBMPlexSans,
            color: EColor.Gray900,
            fontSize: 16,
            lineHeight: '22px',
            fontWeight: 400,
            borderRadius: 5,
            padding: '9px 13px',
            border: `1px solid ${EColor.Gray700}`,
            '&.MuiInputBase-inputTypeSearch': {
              marginTop: 0,
              border: 'none',
            },
            '&:focus': {
              borderRadius: 5,
            },
            '&:read-only': {
              '&:not(.MuiSelect-select)': {
                background: EColor.Gray100,
                border: `1px solid ${EColor.Gray400}`,
                color: EColor.Gray400,
              },
            },
          },
          '& .MuiSvgIcon-root': {
            top: 'calc(50% - 0.75rem)',
            right: 5,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: EFonts.IBMPlexSans,
          color: EColor.Gray900,
          fontSize: 14,
          lineHeight: '14px',
          fontWeight: 600,
          transform: 'none',
          '&.Mui-focused': {
            color: EColor.Gray900,
          },
          '& .MuiInputLabel-asterisk': {
            color: EColor.Red,
            marginLeft: 2,
          },
          '& + .MuiInputBase-root': {
            marginTop: 20,
          },
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'section' },
          style: {
            border: 0,
            borderTop: `1px solid ${EColor.Gray200}`,
            opacity: 0.3,
            marginTop: 30,
            marginBottom: 20,
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          paddingLeft: '8px !important',
          paddingRight: '8px !important',
          paddingTop: '12px !important',
          paddingBottom: '12px !important',
        },
        root: {
          '&.Mui-disabled': {
            background: EColor.Gray100,
          },
        },
      },
      variants: [
        {
          props: { variant: 'black' },
          style: {
            backgroundColor: EColor.Gray800,
            color: EColor.White,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '17px',
            padding: '5px 18px',
            '&:hover': {
              backgroundColor: EColor.Gray800,
              color: EColor.White,
            },
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.50)',
            },
          },
        },
        {
          props: { variant: 'containedGreen' },
          style: {
            backgroundColor: EColor.Green,
            color: EColor.Dark500,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '17px',
            padding: '5px 18px',
            '&:hover': {
              backgroundColor: EColor.Green,
              color: EColor.Dark500,
            },
          },
        },
        {
          props: { variant: 'containedPink' },
          style: {
            backgroundColor: EColor.Pink,
            color: EColor.Dark500,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '17px',
            '&:hover': {
              backgroundColor: EColor.Pink,
              color: EColor.Dark500,
            },
          },
        },
        {
          props: { variant: 'containedDarkGrey' },
          style: {
            backgroundColor: EColor.Gray800,
            color: EColor.White,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '15px',
            padding: '5px 18px',
            '&:hover': {
              backgroundColor: EColor.Gray800,
              color: EColor.White,
            },
          },
        },
        {
          props: { variant: 'containedGrey' },
          style: {
            backgroundColor: EColor.LightBlue,
            color: EColor.Black,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '15px',
            '&:hover': {
              backgroundColor: EColor.LightBlue,
              color: EColor.Black,
            },
          },
        },
        {
          props: { variant: 'containedLightGrey' },
          style: {
            backgroundColor: EColor.Neutral500,
            color: EColor.Gray300,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '15px',
            padding: '5px 18px',
            '&:hover': {
              backgroundColor: EColor.Neutral500,
              color: EColor.Gray300,
            },
          },
        },
        {
          props: { variant: 'containedWhite' },
          style: {
            backgroundColor: EColor.White,
            border: `1px solid ${EColor.Gray800}`,
            color: EColor.Gray800,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '15px',
            padding: '5px 18px',
            '&:hover': {
              backgroundColor: EColor.White,
              color: EColor.Gray800,
            },
          },
        },
        {
          props: { variant: 'mainToolbar' },
          style: {
            padding: '14px 20px',
            minWidth: 0,
            color: EColor.Black,
            fontFamily: EFonts.IBMPlexSans,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '21px',
            backgroundColor: EColor.White,
            border: 0,
            '&:hover': {
              backgroundColor: EColor.White,
              color: EColor.Green,
            },
            '& .MuiSvgIcon-root': {
              marginLeft: 20,
            },
          },
        },
        {
          props: { variant: 'secondaryToolbar' },
          style: {
            padding: '16px 24px',
            minWidth: 0,
            color: EColor.Dark700,
            fontFamily: EFonts.Graphik,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '17px',
            backgroundColor: EColor.Pink,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: EColor.Pink,
              color: EColor.Dark700,
            },
          },
        },
        {
          props: { variant: 'action' },
          style: {
            color: EColor.Dark500,
            fontWeight: 600,
            fontSize: 13.2154,
            lineHeight: '17px',
            textTransform: 'uppercase',
            fontFamily: EFonts.IBMPlexSans,
            '&:hover': {
              backgroundColor: 'transparent',
              color: EColor.Green,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            fontFamily: EFonts.Graphik,
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '18px',
            color: EColor.Black,
            textDecorationLine: 'underline',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: EColor.Green,
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': { color: EColor.Gray100 },
          '&.Mui-checked': {
            '& .MuiSvgIcon-root': { color: EColor.Green },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: EColor.Gray400,
            fontSize: 14,
          },
          color: EColor.Black,
        },
        root: {
          '&:hover:not(.Mui-disabled):before': {
            border: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          padding: '30px 20px 22px',
          minWidth: 432,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: EFonts.IBMPlexSans,
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '15px',
          textTransform: 'uppercase',
          color: EColor.Dark500,
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '30px 0',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 62,
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '15px',
          height: 24,
          color: EColor.White,
          marginLeft: 6,
          '&:first-of-type': {
            marginLeft: 0,
          },
        },
      },
      variants: [
        {
          props: { color: 'error' },
          style: {
            background: EColor.Red,
          },
        },
        {
          props: { color: 'success' },
          style: {
            background: EColor.Green,
          },
        },
        {
          props: { color: 'info' },
          style: {
            background: EColor.Violet,
          },
        },
        {
          props: { color: 'primary' },
          style: {
            background: EColor.DarkGreen,
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            background: EColor.Black,
          },
        },
        {
          props: { color: 'warning' },
          style: {
            background: EColor.Orange,
          },
        },
        {
          props: { color: 'default' },
          style: {
            background: EColor.Gray800,
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { color: EColor.Gray500 },
      },
    },
    MuiMenu: {
      variants: [
        {
          props: { variant: 'menu' },
          style: {
            '& a': {
              textDecoration: 'none',
              color: 'inherit',
            },
            '& .Mui-selected': {
              backgroundColor: `${EColor.Dark900} !important`,
              opacity: '1 !important',
            },
            '& .MuiPaper-root': {
              backgroundColor: EColor.Dark900,
              width: 233,
              borderRadius: '0 0 8px 8px',
              maxHeight: 438,
            },
            '& .MuiListItemIcon-root': {
              minWidth: '20px !important',
              marginRight: 6,
            },
            '& .MuiMenuItem-root': {
              color: EColor.White,
              opacity: 0.4,
              fontWeight: 600,
              fontSize: 17,
              lineHeight: '19px',
              padding: 0,
              backgroundColor: EColor.Dark900,
              '& a': {
                padding: '12px 16px',
                display: 'block',
              },
              '&:hover': {
                backgroundColor: EColor.Dark900,
                opacity: 1,
              },
              '&.AddMarketplace': {
                opacity: 1,
                fontFamily: EFonts.IBMPlexSans,
                fontSize: 13,
                lineHeight: '17px',
                textTransform: 'uppercase',
              },
            },
          },
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'block',
        },
      },
    },
    MuiToggleButtonGroup: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            '.MuiInputLabel-root + &': {
              marginTop: 25,
            },
            '& .MuiToggleButtonGroup-grouped': {
              marginRight: 20,
              borderRadius: 100,
              '&:not(:last-of-type)': {
                borderTopRightRadius: 100,
                borderBottomRightRadius: 100,
              },
              '&:not(:first-of-type)': {
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100,
                marginLeft: 0,
                borderLeft: `1px solid ${EColor.Green}`,
                '&.Mui-disabled': {
                  border: `1px solid ${EColor.Gray300}`,
                },
              },
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            backgroundColor: EColor.Neutral500,
            border: `1px solid ${EColor.Gray300}`,
            color: EColor.Dark500,
            fontSize: 14,
            lineHeight: '15px',
            padding: '12px 24px',
            textTransform: 'unset',
            '&:hover': {
              backgroundColor: EColor.LightGreen,
              color: EColor.Dark500,
            },
            '&.Mui-selected': {
              backgroundColor: EColor.LightGreen,
              color: EColor.Dark500,
              border: `1px solid ${EColor.Green}`,
              '&:hover': {
                backgroundColor: EColor.LightGreen,
                color: EColor.Dark500,
              },
            },
          },
        },
      ],
    },
  },
  navigation: {
    background:
      'linear-gradient(181.98deg, #060606 2.77%, #272629 70.16%, rgba(35, 39, 33, 0.98) 113.72%, #212221 128.68%)',
    width: 266,
    fontSize: 14,
    lineHeight: '14px',
    borderRadius: 5,
    margin: '0 20px',
    toolbarHeight: 75,
  },
  aside: {
    width: 289,
  },
  content: {
    padding: '8px 20px',
    border: `1px solid ${EColor.Neutral700}`,
    dottedBackground: {
      width: '100%',
      background: `linear-gradient(90deg, ${EColor.White} 21px, transparent 1%) center, linear-gradient(${EColor.White} 21px, transparent 1%) center, ${EColor.Gray300}`,
      backgroundSize: `22px 22px`,
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
    },
  },
  fonts: {
    primary: EFonts.Graphik,
    secondary: EFonts.IBMPlexSans,
  },
  colors: {
    primary: {
      dark: EColor.Dark500,
      green: EColor.Green,
      white: EColor.White,
    },
    secondary: {
      greeny: EColor.DarkGreen,
      purple: EColor.Violet,
      darkGrey: EColor.Gray800,
      pink: EColor.Pink,
    },
    tertiary: {
      grey: EColor.Gray300,
      darkGrey: EColor.Gray700,
    },
    other: {
      error: EColor.Red,
      border: EColor.Gray200,
      yellow: EColor.Yellow,
      ethScanBlue: EColor.Navy,
    },
    collection: {
      [MarketCollectionStatus.Active]: EColor.DarkGreen,
      [MarketCollectionStatus.Inactive]: EColor.Violet,
      [MarketCollectionStatus.Archived]: EColor.Gray800,
    },
  },
  palette: {
    primary: {
      main: EColor.Green,
    },
  },
})

export default theme
