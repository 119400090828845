import { ParsedUrlQuery } from 'querystring'

export const deleteEmptyValuesFromQuery = (
  obj: Record<string, string>,
  query: ParsedUrlQuery
) => {
  const newQuery = { ...query, ...obj }
  Object.keys(newQuery).forEach(key => {
    if (newQuery[key] === '') {
      delete newQuery[key]
    }
  })
  return newQuery
}
