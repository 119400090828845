/**
 * @link https://www.figma.com/file/R4Nvl4S10oTMsTUDpcJuyd/Mint-Design-Library?node-id=3%3A12401
 */
export enum EColor {
  Violet = '#B32B92',

  LightPink = '#FFE8E8',
  Pink = '#FFC3C4',

  LightGreen = '#E8FFF1',
  Green = '#98DF78',
  DarkGreen = '#1AB759',

  LightNavy = '#334155',
  Navy = '#21325B',

  Red = '#F40E0E',

  Orange = '#E7AB12',

  Yellow = '#f9ec31',

  LightBlue = '#cbd5e1',

  White = '#FFFFFF',

  Teal900 = '#1C7670',
  Teal800 = '#209C9E',
  Teal700 = '#23B2B8',
  Teal600 = '#27CCD6',
  Teal500 = '#2BE0ED',
  Teal400 = '#38E5ED',
  Teal300 = '#52ECF1',
  Teal200 = '#7FF3F5',
  Teal100 = '#B0F8F7',
  Teal50 = '#DFFDFC',

  Black = '#000000',
  TransparentBlack = '#00000042',

  LightTextPrimary = '#000000DE',

  Dark100 = '#1C1B1D',
  Dark300 = '#151515',
  Dark500 = '#1C1C1E',
  Dark700 = '#1A1A1C',
  Dark900 = '#1F1F21',

  Neutral100 = '#FCFDFF',
  Neutral200 = '#F0F0F0',
  Neutral500 = '#EDEDED',
  Neutral700 = '#F2F2F2',
  Neutral900 = '#F8F8F8',

  Gray100 = '#E6E6E6',
  Gray200 = '#D4D4D4',
  Gray300 = '#C4C4C4',
  Gray400 = '#A6A6A6',
  Gray500 = '#999999',
  Gray600 = '#6B6B6B',
  Gray700 = '#6E6E6F',
  Gray800 = '#393939',
  Gray900 = '#2C2A28',
}
