import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormProps } from 'react-hook-form'
import * as Yup from 'yup'
import Lazy from 'yup/lib/Lazy'

import { Shape } from '@types'

/**
 * This function is type inference ready and will auto-validate the useForm with the proper values.
 *
 * @link https://javascript.plainenglish.io/a-react-hook-form-typescript-ready-hook-with-yup-validator-3e5b9e0bd90e
 * If you don't already have the schema or use a dynamic schema, consider useFormWithSchemaBuilder()
 *
 * @param schema - A valid you schema
 * @param useFormProps
 * @returns
 */
// TODO: fix return properties, it is not possible to reset values
export function useFormWithSchema<T>(
  schema: Yup.ObjectSchema<Shape<T>> | Lazy<any>,
  useFormProps?: UseFormProps<T>
) {
  return useForm({ ...useFormProps, resolver: yupResolver(schema) })
}
