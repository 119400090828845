import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const UserManagementIcon = memo(
  createSvgIcon(
    <svg viewBox="0 0 38 32" fill="none">
      <path
        d="M16.838 16.9624C16.4346 16.9441 16.0313 16.9258 15.6096 16.9258C11.173 16.9258 7.02963 18.1541 3.4913 20.2624C1.87796 21.2158 0.942963 23.0124 0.942963 24.9008V27.9258C0.942963 28.9341 1.76796 29.7591 2.7763 29.7591H17.9196C16.4713 27.6874 15.6096 25.1574 15.6096 22.4258C15.6096 20.4641 16.068 18.6308 16.838 16.9624Z"
        fill="#94A3B8"
      />
      <path
        d="M15.6096 15.0924C19.6597 15.0924 22.943 11.8092 22.943 7.75911C22.943 3.70903 19.6597 0.425781 15.6096 0.425781C11.5595 0.425781 8.2763 3.70903 8.2763 7.75911C8.2763 11.8092 11.5595 15.0924 15.6096 15.0924Z"
        fill="#94A3B8"
      />
      <path
        d="M35.318 22.4258C35.318 22.0224 35.263 21.6558 35.208 21.2708L36.748 19.9324C37.078 19.6391 37.1513 19.1624 36.9313 18.7774L35.8496 16.9074C35.6296 16.5224 35.1713 16.3574 34.768 16.5041L32.8246 17.1641C32.238 16.6691 31.578 16.2841 30.8446 16.0091L30.4413 14.0108C30.3496 13.5891 29.983 13.2774 29.543 13.2774H27.3796C26.9396 13.2774 26.573 13.5891 26.4813 14.0108L26.078 16.0091C25.3446 16.2841 24.6846 16.6691 24.098 17.1641L22.1546 16.5041C21.733 16.3574 21.293 16.5408 21.073 16.9074L19.9913 18.7774C19.7713 19.1624 19.8446 19.6391 20.1746 19.9324L21.7146 21.2708C21.6596 21.6558 21.6046 22.0224 21.6046 22.4258C21.6046 22.8291 21.6596 23.1958 21.7146 23.5808L20.1746 24.9191C19.8446 25.2124 19.7713 25.6891 19.9913 26.0741L21.073 27.9441C21.293 28.3291 21.7513 28.4941 22.1546 28.3474L24.098 27.6874C24.6846 28.1824 25.3446 28.5674 26.078 28.8424L26.4813 30.8408C26.573 31.2624 26.9396 31.5741 27.3796 31.5741H29.543C29.983 31.5741 30.3496 31.2624 30.4413 30.8408L30.8446 28.8424C31.578 28.5674 32.238 28.1824 32.8246 27.6874L34.768 28.3474C35.1896 28.4941 35.6296 28.3108 35.8496 27.9441L36.9313 26.0741C37.1513 25.6891 37.078 25.2124 36.748 24.9191L35.208 23.5808C35.263 23.1958 35.318 22.8291 35.318 22.4258ZM28.443 26.0924C26.4263 26.0924 24.7763 24.4424 24.7763 22.4258C24.7763 20.4091 26.4263 18.7591 28.443 18.7591C30.4596 18.7591 32.1096 20.4091 32.1096 22.4258C32.1096 24.4424 30.4596 26.0924 28.443 26.0924Z"
        fill="#94A3B8"
      />
    </svg>,
    'UserManagementIcon'
  )
)
