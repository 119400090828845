import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { deleteEmptyValuesFromQuery } from '@utils'

export enum EListViewTypes {
  row = 'row',
  column = 'column',
}

interface ListContextInterface {
  direction: EListViewTypes
  handleDirection: (
    event: React.MouseEvent<HTMLElement>,
    newDirection: EListViewTypes
  ) => void
  handleNextPage: () => void
  handlePrevPage: () => void
  handleOnSearch: (search: Record<string, string>) => void
  asGrid: boolean
}

const ListContext = createContext<ListContextInterface | null>(null)

interface ListProviderProps {
  children: React.ReactNode
}

const ListProvider = ({ children }: ListProviderProps) => {
  const [direction, setDirection] = useState<EListViewTypes>(EListViewTypes.row)
  const { query, pathname, replace } = useRouter()

  const handleDirection = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newDirection: EListViewTypes) => {
      if (newDirection) {
        setDirection(newDirection)
      }
    },
    []
  )

  const handleNextPage = useCallback(
    () =>
      replace({
        pathname,
        query: { ...query, page: Number(query.page ?? 0) + 1 },
      }),
    [pathname, query, replace]
  )

  const handlePrevPage = useCallback(() => {
    const currentPage = Number(query?.page) ?? 0

    if (currentPage === 1) {
      delete query.page
    } else {
      query.page = `${currentPage - 1}`
    }
    return replace({
      pathname,
      query,
    })
  }, [pathname, query, replace])

  const handleOnSearch = useCallback(
    (search: Record<string, string>) => {
      return replace({
        pathname,
        query: {
          ...deleteEmptyValuesFromQuery(search, query),
        },
      })
    },
    [pathname, replace, query]
  )

  const value = useMemo(
    () => ({
      direction,
      asGrid: direction === EListViewTypes.row,
      handleDirection,
      handleNextPage,
      handlePrevPage,
      handleOnSearch,
    }),
    [direction, handleDirection, handleNextPage, handlePrevPage, handleOnSearch]
  )

  return <ListContext.Provider value={value}>{children}</ListContext.Provider>
}

const useList = () => {
  const context = useContext(ListContext)

  if (!context) {
    throw new Error('useList should be inside ListProvider')
  }

  return context
}

export { ListProvider, useList }
