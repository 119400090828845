import { useRouter } from 'next/router'

import LeftIcon from '@mui/icons-material/ChevronLeft'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const ReturnHeader: React.FC<{
  path?: string
  returnText?: string
  returnComponent?: React.ReactNode
  returnAction?: () => void
}> = ({ path, returnText, returnAction, returnComponent }) => {
  const router = useRouter()
  return (
    <>
      {returnComponent || (
        <StyledButton
          startIcon={<LeftIcon />}
          onClick={() => {
            if (returnAction) return returnAction()
            return path ? router.push(path) : router.back()
          }}
        >
          {returnText || 'Go Back'}
        </StyledButton>
      )}
    </>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.fonts.secondary,
  fontWeight: 600,
  fontSize: 13.2154,
  lineHeight: '17px',
  textTransform: 'uppercase',
  opacity: 0.4,
  padding: '14px 20px',
  color: theme.colors.primary.dark,

  '&:hover': {
    opacity: 1,
    background: 'transparent',
  },
  gridArea: 'return',
  justifyContent: 'left',
}))
