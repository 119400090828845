import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const AddUserIcon = memo(
  createSvgIcon(
    <svg viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4999 7.50016C14.3416 7.50016 15.8333 6.0085 15.8333 4.16683C15.8333 2.32516 14.3416 0.833496 12.4999 0.833496C10.6583 0.833496 9.16658 2.32516 9.16658 4.16683C9.16658 6.0085 10.6583 7.50016 12.4999 7.50016ZM4.99992 5.8335V3.3335H3.33325V5.8335H0.833252V7.50016H3.33325V10.0002H4.99992V7.50016H7.49992V5.8335H4.99992ZM12.4999 9.16683C10.2749 9.16683 5.83325 10.2835 5.83325 12.5002V14.1668H19.1666V12.5002C19.1666 10.2835 14.7249 9.16683 12.4999 9.16683Z"
        fill="black"
      />
    </svg>,
    'AddUserIcon'
  )
)
