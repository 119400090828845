import * as yup from 'yup'

import { CreateOrgByUserMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

const NAME = 'name'
const HANDLE = 'handle'

export const createOrgByUserMutationValidation = yup.object<
  Shape<CreateOrgByUserMutationVariables>
>({
  [NAME]: yup
    .string()
    .required(`${NAME} is required.`)
    .min(3, 'Min length is 3.'),
  [HANDLE]: yup
    .string()
    .required(`${HANDLE} is required.`)
    .min(3, 'Min length is 3.'),
})
