import { useEffect, useState } from 'react'

import GridViewIcon from '@mui/icons-material/GridView'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { EListViewTypes, useList } from './ListContext'

interface ListControlsProps {
  actions?: React.ReactNode
  hideControls?: boolean
  column?: boolean
}

export const ListControls = ({
  actions,
  hideControls,
  column,
}: ListControlsProps) => {
  const { direction, handleDirection } = useList()
  const [loading, setLoading] = useState<boolean | undefined>(
    column ? column : false
  )
  useEffect(() => {
    if (loading && loading != undefined) {
      document.getElementById('column')?.click()
    }
    return () => setLoading(false)
  }, [])
  return (
    <Box
      sx={{
        padding: '0px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: theme => theme.content.border,
        height: 50,
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        {actions}
      </Stack>

      {!hideControls && (
        <StyledToggleButtonGroup
          value={direction}
          exclusive
          sx={{ visibility: column ? 'hidden' : 'none' }}
          onChange={handleDirection}
          aria-label="list alignment"
          size="small"
        >
          <ToggleButton
            id="column"
            value={EListViewTypes.column}
            aria-label={EListViewTypes.column}
          >
            <MenuIcon />
          </ToggleButton>
          <ToggleButton
            id="row"
            value={EListViewTypes.row}
            aria-label={EListViewTypes.row}
          >
            <GridViewIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
      )}
    </Box>
  )
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderLeft: theme.content.border,
  borderRadius: 0,
  paddingLeft: 10,
  '& .MuiButtonBase-root.MuiToggleButton-root': {
    border: 'none',
    color: theme.colors.primary.dark,
    padding: '5px 0 5px 5px',
    borderRadius: 0,
    '&:hover': {
      color: theme.colors.primary.green,
      backgroundColor: 'transparent',
    },
    '&.Mui-selected': {
      color: theme.colors.primary.green,
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.colors.primary.green,
        backgroundColor: 'transparent',
      },
    },
  },
}))
