import Box from '@mui/material/Box'

interface ContentProps {
  children?: React.ReactNode
}

export const Content: React.FunctionComponent<ContentProps> = ({
  children,
}) => (
  <Box display="flex" flexGrow={1} className="Content" width="100%">
    {children}
  </Box>
)
