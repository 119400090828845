import { Theme, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'

import { CopyClipboardButton } from './CopyClipboardButton'

interface TextBlockProps {
  label?: string
  text?: string | number
  sx?: SxProps<Theme>
  link?: string
  withCopyButton?: boolean
  withEllipsis?: boolean
  asList?: boolean
}

export const TextBlock: React.FC<TextBlockProps> = ({
  label,
  text,
  sx,
  link,
  withCopyButton,
  withEllipsis,
  asList,
}) => {
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box flexGrow="1" width="80%">
        <Typography variant="infoLabel" paragraph sx={sx}>
          {label}
        </Typography>
        <Typography
          variant="info"
          paragraph
          sx={theme => ({
            sx,
            wordBreak: 'break-word',
            ...(asList && { mb: 0 }),
            ...(withEllipsis && { ...theme.content.ellipsis }),
          })}
        >
          {link ? (
            <Typography
              component="a"
              sx={theme.content.ellipsis}
              href={link}
              target="_blank"
            >
              {link}
            </Typography>
          ) : !!text ? (
            text
          ) : (
            '-'
          )}
        </Typography>
      </Box>
      {withCopyButton && text && (
        <CopyClipboardButton value={text as string} plain />
      )}
    </Box>
  )
}
