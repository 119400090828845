import * as yup from 'yup'

import { UpdateAfterPaymentTransferSuspendTimeMutationVariables } from '@services/graphql/generated'
import { Shape } from '@types'

export const updateAfterPaymentTransferSuspendTimeMutationValidation =
  yup.object<Shape<UpdateAfterPaymentTransferSuspendTimeMutationVariables>>({
    afterPaymentTransferSuspendTime: yup
      .string()
      .required('Suspension time is required.'),
    orgID: yup.string().required('Org ID is required.'),
  })
