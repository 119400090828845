import { memo } from 'react'

import { createSvgIcon } from '@mui/material'

export const ThreeDotsIcon = memo(
  createSvgIcon(
    <>
      <path d="M4.61538 2C4.61538 3.10457 3.5822 4 2.30769 4C1.03319 4 0 3.10457 0 2C0 0.895431 1.03319 0 2.30769 0C3.5822 0 4.61538 0.895431 4.61538 2Z" />
      <path d="M12.3077 2C12.3077 3.10457 11.2745 4 10 4C8.7255 4 7.69231 3.10457 7.69231 2C7.69231 0.895431 8.7255 0 10 0C11.2745 0 12.3077 0.895431 12.3077 2Z" />
      <path d="M20 2C20 3.10457 18.9668 4 17.6923 4C16.4178 4 15.3846 3.10457 15.3846 2C15.3846 0.895431 16.4178 0 17.6923 0C18.9668 0 20 0.895431 20 2Z" />
    </>,
    'ThreeDotsIcon'
  )
)
